import getInstance from './instance';

export default {
    updateProfilePicture: (data) => getInstance().put('users/update-profile-picture', data),
    removeProfilePicture: () => getInstance().delete('users/remove-profile-picture'),
    updateName: (data) => getInstance().put('users/update-name', data),
    passwordChange: (data) => getInstance().put('users/password-change', data),
    users: () => getInstance().get('users'),
    invitees: () => getInstance().get('users/invitees'),
    premiumSubscription: (data) => getInstance().post('users/premium/subscribe', data),
    premiumUnsubscription: () => getInstance().post('users/premium/unsubscribe'),
    getPremiumStatus: () => getInstance().get('users/premium'),
}

