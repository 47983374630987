import { Box } from "@mui/material";
import Recorder from "./Recorder";
import { styled } from '@mui/material/styles';

const WrapperStyle = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(-10),
    height: `calc(100% + ${theme.spacing(10)})`,
    overflow: "auto"
}));


const RecorderWrapper = () => {
    return (
        <WrapperStyle>
            <Recorder />
        </WrapperStyle>
    );
}

export default RecorderWrapper;