import { useEffect, useState } from 'react';
import axios from 'axios';

const useEditData = (url, callback) => {

    useEffect(() => {
        if (!url) return
        const abortCont = new AbortController();
        axios({ url: process.env.REACT_APP_API_HOST + url, method: 'GET', withCredentials: true, timeout: 30000 })
            .then(data => {
                callback(data.data);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.log(err.message);
                }
            });
        return () => abortCont.abort();
    }, [url]);
}

export default useEditData;