import React from "react";
import Ansi from "./AnsiToReact";
import Chalk from 'chalk';
const chalk = new Chalk.Instance({level: 3});

const TerminalInputLine = ({prompt, styleInput, children} : {children?: string, prompt?: string, styleInput?: boolean}) => {
  const [cmd] = children?.split(' ')||[];
  const terminalCmd = styleInput? children?.replace(cmd, chalk.yellowBright(cmd)) : children

  return (
    <div className={"react-terminal-line react-terminal-input react-terminal-active-input"} data-terminal-prompt={ prompt } key="terminal-line-prompt" >
    <Ansi>{ terminalCmd }</Ansi>
    </div>
  );
} 

export default TerminalInputLine;