import auth from './auth';
import users from './users';
import meetings from './meetings';
import sessions from './sessions';
import invitations from './invitations';
import comments from './comments';
import forums from './forums';
import notification from './notification';

export default {
    auth,
    users,
    meetings,
    sessions,
    invitations,
    comments,
    forums,
    notification
};
