import React from 'react';
import { makeStyles } from '@mui/styles';

// material
import { Avatar } from '@mui/material';
import {
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown
} from '@mui/material/colors';

const colors = [
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown
];

function DefaultAvatar({ user, ...props }) {
    const generateRandomeNumber = ({ firstname, lastname }) => {
        let date = new Date().getDate();

        return (firstname?.charCodeAt(0) + lastname?.charCodeAt(0)) % colors.length;
    };

    const getFirstTwoAlphabet = ({ firstname, lastname }) => {
        return firstname?.charAt(0) + lastname?.charAt(0);
    };
    const useStyles = makeStyles((theme) => ({
        avatarColor: {
            color: theme.palette.getContrastText(
                colors[generateRandomeNumber(user) || 1][500]
            ),
            backgroundColor: colors[generateRandomeNumber(user)][500]
        }
    }));

    return <Avatar className={useStyles().avatarColor} {...props}>{getFirstTwoAlphabet(user)}</Avatar>;
}

export default DefaultAvatar;
