import React from 'react';
import { useParams } from 'react-router-dom';
// material
import {
    Container,
    Grid,
    Box,
    Typography
} from '@mui/material';

// components
import Page from 'src/components/Page';
import ResultItem from './ResultItem'
import useFetch from 'src/hooks/useFetch';
import Footer from 'src/components/Footer';

export default function LandingPage() {
    const { query } = useParams()
    const [results, loading, error] = useFetch(`/search?q=${query}`)
    return (
        <Page title="CodeLens | Search Results">
            <Container maxWidth="xl" sx={{ minHeight: '100%', pb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2, mt: 1 }}>
                    <Typography variant="h5" component="h2">
                        {results.length} items found
                    </Typography>
                    <Typography variant="subtitle1" component="h2">
                        Search result for <u>{query}</u>
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    {results.map((result, key) => (
                        <ResultItem key={key} post={result} />
                    ))}
                </Grid>
            </Container>
            <Footer />
        </Page>
    );
}
