//mui components
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

import { Helmet } from 'react-helmet-async';

//components
import MainFeaturedPost from './MainFeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from 'src/components/Footer';

const mainFeaturedPost = {
    title: 'CodeLens - Online Code Leaning Platform',
    description:
        `Learning programming can open up a wide range of opportunities for career growth and development.
        From software development to data analysis and machine learning, programming skills can be used in a variety of roles across different industries.
        `,
    image: 'https://source.unsplash.com/random',
    imageText: 'CodeLens',
    linkText: 'Continue reading…',
};


const sidebar = {
    title: 'About',
    description:
        `Learn to code with our interactive video tutorials! Our platform allows you to watch video tutorials while
        accessing the code on the screen. With the ability to copy and modify the code, you can practice and experiment with
        different coding techniques. Join our community of learners today and start your journey towards
        becoming a proficient coder!`,
    archives: [
        { title: 'IEEE INSL 2022', url: '#' },
        { title: 'YGC 2022', url: '#' },
        { title: 'ImagineCup 2023', url: '#' },
        { title: 'Haxmas 2022', url: '#' },
        { title: 'UIY 2022', url: '#' },
        { title: 'TadHack 2022', url: '#' },
    ],
    social: [
        { name: 'GitHub', icon: GitHubIcon },
        { name: 'Twitter', icon: TwitterIcon },
        { name: 'Facebook', icon: FacebookIcon },
    ],
};

export default function About() {
    return (
        <>
            <Helmet>
                <title>CodeLens | About</title>
            </Helmet>
            <Container maxWidth="lg" sx={{ my: 3 }}>
                <main>
                    <MainFeaturedPost post={mainFeaturedPost} />
                    <Grid container spacing={5} sx={{ mt: 3 }}>
                        <Main />
                        <Sidebar
                            title={sidebar.title}
                            description={sidebar.description}
                            archives={sidebar.archives}
                            social={sidebar.social}
                        />
                    </Grid>
                </main>
            </Container>
            <Footer />
        </>
    );
}