import menuOutline from '@iconify/icons-eva/menu-outline';
// icon
import { Icon } from '@iconify/react';
import { Box, Drawer } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from 'src/contexts/AuthContext';
import { MHidden } from '../../components/@material-extend';
// components
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import PrivateSidebarConfig from './PrivateSidebarConfig';
//
import PublicSidebarConfig from './PublicSidebarConfig';


const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    }
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

LandingPageSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func
};

export default function LandingPageSidebar({ isOpenSidebar, onCloseSidebar, user }) {

    const { loginStatus } = useAuth()

    const renderContent = (
        <Scrollbar
            sx={{
                height: '100%',
                '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
            }}
        >

            <Box sx={{ pr: 3, py: 0, display: 'inline-flex', my: 3 }}>
                <div style={{ width: 20 }}></div>
                <div className="px-2" style={{ paddingTop: 24, color: 'black', cursor: 'pointer' }} onClick={onCloseSidebar}>
                    {getIcon(menuOutline)}
                </div>
                <Box component={RouterLink} to="/">
                    <Logo />
                </Box>
            </Box>

            {/* <Box sx={{ mb: 5, mx: 2.5 }}>
            <Link underline="none" component={RouterLink} to="#">
            <AccountStyle>
                <Avatar src={user.profilePicture} alt="photoURL" />
                <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    {`${user.firstname} ${user.lastname}`}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    account.role
                </Typography>
                </Box>
            </AccountStyle>
            </Link>
        </Box> */}

            <NavSection navConfig={PublicSidebarConfig} />
            {loginStatus && <NavSection navConfig={PrivateSidebarConfig} />}
            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <RootStyle>
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default'
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
