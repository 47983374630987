import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import meetingListdFill from '@iconify/icons-eva/calendar-fill';
import forum from '@iconify/icons-eva/globe-fill'

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const publicSidebarConfig = [
  {
    title: 'home',
    path: '/',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'forums',
    path: '/forums',
    icon: getIcon(forum)
  }
];

export default publicSidebarConfig;
