import React, { useState } from 'react';
import { Grid, Box, LinearProgress, Container, Alert } from '@mui/material';

import useFetch from 'src/hooks/useFetch'
import SessionItem from './SessionItem';
import PromptPremium from 'src/components/sessionLIstItem/PromptPremium';

export default function SessionList({ tag }) {
    const [sessions, isLoading] = useFetch('/sessions' + (tag ? `/tagged/${tag}` : ''))
    const [promptPremium, setPromptPremium] = useState(false)
    const [premiumSession, setPremiumSession] = useState(null);

    const handleOpen = () => {
        setPromptPremium(true);
    };

    if (isLoading) return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress sx={{ height: '5px' }} />
        </Box>
    )
    if (sessions.length === 0) return (
        <Container maxWidth="md" sx={{ mt: 4, textAlign: 'center' }}>
            <Alert severity="info">No sessions found</Alert>
        </Container>
    )
    return (
        <Container maxWidth="xl">
            <Grid container spacing={3} sx={{ mt: 2 }}>
                {sessions.map((session, key) => (
                    <SessionItem key={key} post={session} onClickPremium={handleOpen} />
                ))}
            </Grid>
            <PromptPremium open={promptPremium} setOpen={setPromptPremium} />
        </Container>
    );
}
