import React from 'react';
// Auth provider
import { AuthProvider } from 'src/contexts/AuthContext';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
// drag and drop
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// ----------------------------------------------------------------------

export default function App() {
    return (
        <DndProvider backend={HTML5Backend}>
            <ThemeConfig>
                <ScrollToTop />
                <AuthProvider>
                    <Router />
                </AuthProvider>
            </ThemeConfig>
        </DndProvider>
    );
}
