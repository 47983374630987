import React from 'react';
import Loader from 'src/components/Loader/Loader';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import api from 'src/services/api';

function GoogleAuthComplete(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const data = queryString.parse(location.search);

    api.auth
        .googleAuthTokenConfirm(data)
        .then((res) => {
            console.log(res.data);
            if (res.data.success) {
                window.open('/home', '_self');
            } else {
                window.open('/login', '_self');
            }
        })
        .catch((err) => {
            window.open('/login', '_self');
        });

    return (
        <div>
            <Loader />
        </div>
    );
}

export default GoogleAuthComplete;
