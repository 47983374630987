import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ConfirmationModal } from './../../ConfirmationModal';
import RegistrationSuccesfulModal from './RegistrationSuccesfulModal';

import api from './../../../services/api';

function ConfirmCodeForm({ email }) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [activationCode, setActivationCode] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertServerity, setAlertServerity] = useState('success');

    const onConfirm = () => {
        setLoading(true);
        api.auth
            .confirmRegister({ email, activationCode })
            .then((res) => {
                if (res.data.success) {
                    setAlertMsg(res.data.msg);
                    setAlertServerity('success');
                    setShowAlert(true);
                    setLoading(false);

                    setAlertMsg(res.data.msg);
                    setAlertServerity('success');
                    setShowAlert(true);
                    ConfirmationModal({
                        title: <b>Registration Complete</b>,
                        content: <RegistrationSuccesfulModal />,
                        buttons: [
                            {
                                label: 'Sign In',
                                onClick: () => {
                                    navigate('/login', { replace: true });
                                },
                                autoFocus: true
                            },
                            {
                                label: 'Close',
                                onClick: false,
                                autoFocus: false
                            }
                        ]
                    });
                } else {
                    setAlertMsg(res.data.msg);
                    setAlertServerity('error');
                    setShowAlert(true);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setAlertMsg('Something wrong..!');
                setAlertServerity('error');
                setShowAlert(true);
                setLoading(false);
            });
    };

    const hideAlert = () => {
        setShowAlert(false);
        setAlertMsg('');
        setAlertServerity('success');
    };

    return (
        <>
            {showAlert && (
                <Alert onClose={() => hideAlert()} variant="filled" severity={alertServerity}>
                    {alertMsg}
                </Alert>
            )}
            <Stack marginTop={2} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                    fullWidth
                    label="Confirmation Code "
                    defaultValue={activationCode}
                    value={activationCode}
                    onChange={(event) => setActivationCode(event.target.value)}
                />

                <LoadingButton
                    size="large"
                    variant="contained"
                    loading={isLoading}
                    disabled={!(activationCode && activationCode.length >= 5)}
                    onClick={onConfirm}
                >
                    Confirm
                </LoadingButton>
            </Stack>
        </>
    );
}

export default ConfirmCodeForm;
