import React, { useState, useEffect } from 'react';
import { CommentSection } from './module/index';
// import 'react-comments-section/dist/index.css';
// Auth context
import { useAuth } from 'src/contexts/AuthContext';

import api from 'src/services/api';
import { useParams } from 'react-router-dom';

function CommentsSection(props) {
    const { user, loginStatus } = useAuth();
    const { id } = useParams();
    const [comment, setComment] = useState([]);
    const [commentCount, setCommentCount] = useState(0);

    const userId = loginStatus ? user._id : '';
    const name = loginStatus ? `${user.firstname} ${user.lastname}` : '';
    const avatarUrl = loginStatus
        ? user.profilePicture
            ? user.profilePicture
            : `https://ui-avatars.com/api/name=${user.firstname.charAt(0) + user.lastname.charAt(0)
            }&background=random`
        : '';
    const signinUrl = '/login';
    const signupUrl = '/register';

    useEffect(() => {
        api.comments
            .getComments(id)
            .then((res) => {
                setCommentCount(res.data.length);
                setComment(formattedCommetArray(res.data));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const addComment = async (data) => {
        const res = await api.comments.addComment({ ...data, sessionId: id });
        if (res) return true;
        else return false;
    };

    const editComment = async (data) => {
        const res = await api.comments.editComment({ ...data, sessionId: id });
        if (res) return true;
        else return false;
    };

    const deleteComment = async (data) => {
        console.log('A', data);
        const res = await api.comments.deleteComment({ ...data, sessionId: id });
        if (res) return true;
        else return false;
    };

    const formattedCommetArray = (dataArray) => {
        const array = dataArray
            .filter((e) => !e.isReply)
            .map((e, i, a) => {
                return {
                    userId: e.userId,
                    comId: e._id,
                    fullName: e.fullName,
                    text: e.text,
                    avatarUrl: e.avatarUrl
                        ? e.avatarUrl
                        : `https://ui-avatars.com/api/name=${String(e.fullName).charAt(0) +
                        String(e.fullName).split(' ')[1].charAt(0)
                        }&background=random`,
                    createdAt: e.createdAt,
                    replies: dataArray
                        .filter((ee) => ee.isReply && ee.parentCommentId === e._id)
                        .map((x, y) => {
                            return {
                                userId: x.userId,
                                comId: x._id,
                                fullName: x.fullName,
                                text: x.text,
                                avatarUrl: x.avatarUrl
                                    ? x.avatarUrl
                                    : `https://ui-avatars.com/api/name=${String(e.fullName).charAt(0) +
                                    String(e.fullName).split(' ')[1].charAt(0)
                                    }&background=random`,
                                createdAt: x.createdAt
                            };
                        })
                };
            });
        return array;
    };

    return (
        <div className="commentSection">
            <div className="header">{commentCount} Comments</div>
            <div>
                <CommentSection
                    style={{ width: '200px' }}
                    currentUser={userId && { userId: userId, avatarUrl: avatarUrl, name: name }}
                    commentsArray={comment}
                    setComment={setComment}
                    signinUrl={signinUrl}
                    signupUrl={signupUrl}
                    addComment={addComment}
                    editComment={editComment}
                    deleteComment={deleteComment}
                />
            </div>
        </div>
    );
}

export default CommentsSection;
