import eyeFill from '@iconify/icons-eva/eye-fill';
import messageCircleFill from '@iconify/icons-eva/message-circle-fill';
import shareFill from '@iconify/icons-eva/share-fill';
import { Avatar, Box, Card, CardContent, CardMedia, Grid, Link, Stack, Typography } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import { NavLink as RouterLink } from 'react-router-dom';
// utils
import { fDate } from 'src/utils/formatTime';
import SessionItemTag from 'src/components/sessionLIstItem/SessionItemTag';
import SessionAuthor from 'src/components/sessionLIstItem/SessionAuthor';

// ----------------------------------------------------------------------


const TitleStyle = styled(Link)({
    height: 30,
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical'
});

// ----------------------------------------------------------------------

export default function ResultItem({ post, index }) {
    const { cover, title, description, view, comment, share, author, createdAt } = post;
    const latestPostLarge = index === 0;
    const latestPost = index === 1 || index === 2;

    return (
        <Grid item xs={12} sm={latestPostLarge ? 12 : 12} md={latestPostLarge ? 12 : 12}>
            <Card sx={{ position: 'relative', display: 'flex' }}>
                <Grid
                    item
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <CardMedia
                            component="img"
                            sx={{ width: '100%' }}
                            image={`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_DATA_PATH}${post._id}-thumbnail.png`}
                            alt="CodeLens"
                        />
                    </Grid>
                    <Grid
                        item
                        md={8}
                        xs={12}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <TitleStyle
                                    to={"/sessions/" + post._id}
                                    color="inherit"
                                    variant="h5"
                                    underline="hover"
                                    component={RouterLink}
                                    sx={{
                                        ...(latestPostLarge && { typography: 'h5', height: 60 }),
                                        ...((latestPostLarge || latestPost) && {
                                            color: 'common.white'
                                        })
                                    }}
                                >
                                    {title}
                                </TitleStyle>
                                <TitleStyle
                                    to={"/sessions/" + post._id}
                                    color="inherit"
                                    variant="subtitle1"
                                    underline="hover"
                                    component={RouterLink}
                                    sx={{
                                        ...(latestPostLarge && { typography: 'h5', height: 60 }),
                                        ...((latestPostLarge || latestPost) && {
                                            color: 'common.white'
                                        })
                                    }}
                                >
                                    {description}
                                </TitleStyle>
                                <Typography
                                    gutterBottom
                                    variant="caption"
                                    sx={{ color: 'text.disabled', display: 'block' }}
                                >
                                    {fDate(createdAt)}
                                </Typography>
                                <Box sx={{
                                    position: 'relative',
                                    mt: 4,
                                    mb: 8,
                                }}>
                                    {post.tags?.map((tag, id) => (
                                        <SessionItemTag tag={tag} id={id} />
                                    ))}
                                </Box>
                                <SessionAuthor author={author} />
                            </CardContent>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
}
