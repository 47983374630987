import { useState, useEffect } from 'react';
import {
    Alert,
    Container,
    Box,
    CircularProgress
} from '@mui/material';
import api from 'src/services/api';
import PremiumMembershipForm from './PremiumMembershipForm';
import UnsubscribeMembership from './UnsubscribeMembership';

const PremiumUser = () => {
    const [premiumStatus, setPremiumStatus] = useState({})
    const [loading, setLoading] = useState(true)
    let timeout;

    useEffect(() => {
        fetchPremiumStatus()
        return () => {
            if (timeout) clearTimeout(timeout)
        }
    }, [])

    const fetchPremiumStatus = () => {
        api.users.getPremiumStatus().then(res => {
            setPremiumStatus(res.data)
            if (res.data.subscriptionStatus === 'pending') {
                timeout = setTimeout(fetchPremiumStatus, 1000)
            }
            setLoading(false)
        })
    }

    let element = null;

    if (loading) {
        element = <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 100 }}>
            <CircularProgress />
        </Box>
    } else {
        switch (premiumStatus.subscriptionStatus) {
            case 'subscribed':
                element = <>
                    <Alert severity="info" sx={{ mb: 2 }}>You have subscribed as a Premium user</Alert>
                    <UnsubscribeMembership callback={fetchPremiumStatus} />
                </>
                break;
            case 'pending':
                element = <>
                    <Alert severity="info" sx={{ mb: 2 }}>Please follow the instruction given via the SMS to complete the process.</Alert>
                    <UnsubscribeMembership label="Cancel" callback={fetchPremiumStatus} />
                </>
                break;
            default:
                element = <>
                    <Alert severity="info" sx={{ mb: 2 }}>Please provide your phone number to subscribe.</Alert>
                    <PremiumMembershipForm callback={fetchPremiumStatus} />
                </>
        }
    }

    return (
        <Container maxWidth="sm">
            {element}
        </Container>
    );
}

export default PremiumUser;