import { Avatar, Box } from '@mui/material';
import { Link } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const AvatarStyle = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(-2)
}));

// ----------------------------------------------------------------------

const Premium = ({ tag = 'premium', id = 0 }) => {
    return (
        <Link to={`/premium`}>
            <Box
                component="span"
                sx={{
                    bgcolor: 'background.paper',
                    width: 40,
                    height: 40,
                    zIndex: 9,
                    right: id * 48 + 20,
                    bottom: -20,
                    position: 'absolute',
                    borderRadius: '50%'
                }}
            />
            <AvatarStyle
                alt={tag}
                sx={{
                    bgcolor: '#FFD700C0',
                    position: 'absolute',
                    zIndex: 9,
                    right: id * 48 + 24,
                    width: 32,
                    height: 32,
                    left: 'unset'
                }}
            >
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 280.246 280.246" style={{ enableBackground: "new 0 0 280.246 280.246" }} xmlSpace="preserve" width="1em" height="1em">
                    <g>
                        <path style={{ fill: "#BF392C" }} d="M17.568,87.613l35.056-43.754c13.8-17.502,13.319-17.502,26.288-17.502h122.582
		c12.943,0,12.461,0,26.235,17.502l34.986,43.754c23.601,26.288,23.146,26.288,0,52.505l-96.216,96.259
		c-8.471,8.751-12.943,17.502-26.244,17.502c-13.319,0-17.808-8.751-26.288-17.502l-96.408-96.259
		C-5.622,113.9-6.086,113.9,17.568,87.613z"/>
                        <path style={{ fill: "#D78366" }} d="M70.125,131.367l70.007-105.01l70.007,105.01H70.125z" />
                        <path style={{ fill: "#CC7062" }} d="M280.146,113.865v-1.015c-0.525-6.231-6.248-12.785-17.423-25.237l-34.986-43.754
		c-13.774-17.502-13.301-17.502-26.235-17.502h-61.37c0,0,69.981,105.019,70.007,105.01
		C210.909,130.536,280.146,113.865,280.146,113.865z"/>
                        <path style={{ fill: "#CC7062" }} d="M52.624,43.859L17.568,87.613c-11.157,12.4-16.889,18.946-17.449,25.159v1.094
		c0,0,69.228,16.662,70.007,17.502c0.026,0.009,70.007-105.01,70.007-105.01H78.911C65.942,26.357,66.424,26.357,52.624,43.859z"/>
                        <path style={{ fill: "#C5543B" }} d="M210.139,131.367H70.125l60.127,120.263c2.783,1.374,5.959,2.258,10.011,2.258
		c3.885,0,6.966-0.814,9.661-2.083L210.139,131.367z"/>
                        <path style={{ fill: "#E1A28B" }} d="M140.132,131.367l69.709-104.564c-2.144-0.394-4.69-0.446-8.348-0.446H78.911
		c-3.737,0-6.309,0.053-8.471,0.473L140.132,131.367z"/>
                        <g>
                            <path style={{ fill: "#CF7C69" }} d="M218.382,32.369c-5.312-5.872-7.893-6.012-16.889-6.012h-61.37l70.007,105.01L218.382,32.369z" />
                            <path style={{ fill: "#CF7C69" }} d="M61.882,32.491l8.243,98.885l70.007-105.01H78.911C69.837,26.357,67.281,26.497,61.882,32.491z" />
                        </g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                </svg>
            </AvatarStyle>

        </Link>
    );
}

export default Premium;