import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const BufferLoader = ({ buffering }) => {
    if (!buffering) return null
    return (
        <Box sx={{ width: '100%', position: 'absolute' }}>
            <LinearProgress color="primary" />
        </Box>
    );
}

export default BufferLoader;