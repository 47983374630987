import React, { useRef, useState } from 'react';
import { FileUpload, FileUploadProps } from "./FileUpload";
import Page from 'src/components/Page';
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Player from 'src/components/Player';

interface LoadingProp {
  video? : Boolean,
  recordings? : Boolean
}

export default function OfflinePlayer() {
  const [loading, setLoading] = useState<LoadingProp>({video: true, recordings: true})
  const [videoFile, setVideoFile] = useState<string>('')
  const [recordingsFile, setRecordingsFile] = useState<string>('')

  const videoUrlRef = useRef<string>()
  const recordingsRef = useRef<string | ArrayBuffer | null>()

  const videoFileUploadProp: FileUploadProps = {
    id: 'video',
    accept: 'audio/*,video/*',
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (
            event.target.files !== null &&
            event.target?.files?.length > 0
        ) {
            videoUrlRef.current = URL.createObjectURL(event.target.files[0])
            setVideoFile(event.target.value)
            console.log(`Saving ${event.target.value}`)
            setLoading(loading => ({...loading, video: false}))
        }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      videoUrlRef.current = URL.createObjectURL(event.dataTransfer.files[0])
      setVideoFile(event.dataTransfer.files[0].name)
      console.log(`Drop ${event.dataTransfer.files[0].name}`)
      setLoading(loading => ({...loading, video: false}))
    },
  }
  
  const jsonFileUploadProp: FileUploadProps = {
    id: 'recordings',
    accept: '.json,application/json',
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        if (
            event.target.files !== null &&
            event.target?.files?.length > 0
        ) {
          const reader = new FileReader()
          reader.readAsText(event.target.files[0])
          reader.onload = event => {
            if (event.target) {
              console.log() 
              recordingsRef.current = JSON.parse(event.target.result as string)
              setLoading(loading => ({...loading, recordings: false}))
            }
          }
          setRecordingsFile(event.target.value)
          console.log(`Saving ${event.target.value}`)
        }
    },
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      const reader = new FileReader()
      reader.readAsText(event.dataTransfer.files[0])
      reader.onload = event => {
        if (event.target) {
          console.log() 
          recordingsRef.current = JSON.parse(event.target.result as string )
          setLoading(loading => ({...loading, recordings: false}))
        }
      }
      setRecordingsFile(event.dataTransfer.files[0].name)
      console.log(`Drop ${event.dataTransfer.files[0].name}`)
    },
  }

  if (!loading.video && !loading.recordings) return <Player
  // @ts-ignore: Unreachable code error
    session={recordingsRef.current?.metaData}
    // @ts-ignore: Unreachable code error
    records={{current: recordingsRef.current.records}}
    audioURL={videoUrlRef.current}
  />

  return (
    // @ts-ignore: Unreachable code error
    <Page title="Offline Player">
      <Container sx={{mt: 2}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FileUpload {...videoFileUploadProp} fileName={videoFile} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FileUpload {...jsonFileUploadProp} fileName={recordingsFile} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
