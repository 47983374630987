import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack,
    TextField,
    InputAdornment
} from '@mui/material';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import api from 'src/services/api';
// ----------------------------------------------------------------------

export default function PremiumMembershipForm({ callback }) {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    const phoneRegExp = /^[0-9]{9}$/
    const MembershipSchema = Yup.object().shape({
        phone: Yup.string().matches(phoneRegExp, 'Please enter a valid phone number').required('Phone number is required')
    });

    const formik = useFormik({
        initialValues: {
            phone: '',
        },
        validationSchema: MembershipSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            api.users
                .premiumSubscription({
                    phone: values.phone,
                })
                .then((res) => {
                    if (res.data.success) {
                        actions.setSubmitting(false);
                        callback()
                    } else {
                        setAlertMsg(res.data.msg);
                        setShowAlert(true);
                        actions.setSubmitting(false);
                    }
                })
                .catch((err) => {
                    actions.setSubmitting(false);
                    console.log(err);
                });
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const hideAlert = () => {
        setShowAlert(false);
        setAlertMsg('');
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3} mb={2}>
                    {showAlert && (
                        <Alert onClose={() => hideAlert()} variant="filled" severity="error">
                            {alertMsg}
                        </Alert>
                    )}
                    <TextField
                        fullWidth
                        autoComplete="phone"
                        type="phone"
                        label="Phone number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+94</InputAdornment>,
                        }}
                        {...getFieldProps('phone')}
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                    />
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Subscribe
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
}
