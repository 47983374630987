import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

function Main(props) {

    return (
        <Grid
            item
            xs={12}
            md={8}
            sx={{
                '& .markdown': {
                    py: 3,
                },
            }}
        >
            <Typography variant="h6" gutterBottom>
                Journey of Team CODIO
            </Typography>
            <Divider />
            <Typography variant="body1" gutterBottom>
                The journey of Team Codio began with a simple problem that many programming students face: the inability to copy code from a video tutorial and move to different pages while watching the video. This issue can greatly hinder the learning process and slow down progress, leading the team to search for a solution.
                <br />
                <br />


                After a year of designing and development, the team created a prototype of an online code learning platform called Codio. The platform allows users to watch video tutorials while simultaneously being able to copy code from the screen and navigate to different pages. This improved experience leads to faster learning and better retention of information.
                <br />
                <br />


                As the team continued to develop Codio and enter it into various competitions, they faced a new hurdle: the name was already taken by another code learning platform. Undeterred, the team changed the product name to CodeLens and continued to gain success. They were winners in several competitions, including IEEE INSL, YGC, and Microsoft Imagine Cup.
                <br />
                <br />


                The journey of Team Codio is a testament to the power of identifying a problem and working to find a solution. With persistence and hard work, they were able to create a product that not only helped them but also the entire programming learning community.

            </Typography>
        </Grid>
    );
}

export default Main;