import { useState } from 'react'
import FileBrowser, { Icons } from './FileBrowser'
import 'font-awesome/css/font-awesome.min.css'
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import './Demos.css'

const FileManager = ({ files, handleBrowserAction, setFiles, setCurrentFile, activeFile, onFolderOpen }) => {
    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event) => {
        // event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const handleSelectFile = (file) => {
        if (!file) return
        setCurrentFile(file)
    }

    const handleCreateFolder = (key) => {
        setFiles(files => {
            files = files.concat([{
                key: key,
            }])
            handleBrowserAction && handleBrowserAction(files)
            return files
        })
    }

    const handleCreateFile = (key, prefix) => {
        setFiles(files => {
            files = files.concat([{
                key: key,
                content: []
            }])
            handleBrowserAction && handleBrowserAction(files)
            return files
        })
        setCurrentFile({ key })
    }

    const handleRenameFolder = (oldKey, newKey) => {
        setFiles(files => {
            const newFiles = []
            files.map((file) => {
                if (file.key.substr(0, oldKey.length) === oldKey) {
                    newFiles.push({
                        ...file,
                        key: file.key.replace(oldKey, newKey),
                        // modified: +Moment(),
                    })
                } else {
                    newFiles.push(file)
                }
            })
            files = newFiles
            handleBrowserAction && handleBrowserAction(files)
            return files
        })
    }

    const handleRenameFile = (oldKey, newKey) => {
        setFiles(files => {
            const newFiles = []
            files.map((file) => {
                if (file.key === oldKey) {
                    newFiles.push({
                        ...file,
                        key: newKey,
                        // modified: +Moment(),
                    })
                } else {
                    newFiles.push(file)
                }
            })
            files = newFiles
            handleBrowserAction && handleBrowserAction(files)
            return files
        })
    }

    const handleDeleteFolder = (folderKey) => {
        setFiles(files => {
            const newFiles = []
            files.map((file) => {
                if (file.key.substr(0, folderKey.length) !== folderKey) {
                    newFiles.push(file)
                }
            })
            files = newFiles
            handleBrowserAction && handleBrowserAction(files)
            return files
        })
    }

    const handleDeleteFile = (fileKey) => {
        setFiles(files => {
            const newFiles = []
            files.map((file) => {
                if (file.key !== fileKey) {
                    newFiles.push(file)
                }
            })
            files = newFiles
            handleBrowserAction && handleBrowserAction(files)
            return files
        })
    }

    return (
        <>
            <div className="control-section" onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
                <FileBrowser
                    files={files.map(({ content, ...file }) => {
                        if (file.key === activeFile.key) {
                            file.isSelected = true
                        }
                        return file
                    })}
                    icons={Icons.FontAwesome(4)}
                    renderStyle="table"

                    onSelectFile={handleSelectFile}
                    onFolderOpen={onFolderOpen}
                    onCreateFolder={handleCreateFolder}
                    onCreateFile={handleCreateFile}
                    onMoveFolder={handleRenameFolder}
                    onMoveFile={handleRenameFile}
                    onRenameFolder={handleRenameFolder}
                    onRenameFile={handleRenameFile}
                    onDeleteFolder={handleDeleteFolder}
                    onDeleteFile={handleDeleteFile}

                    contextMenuObj={{
                        contextMenu, handleClose
                    }}
                />
            </div>
        </>
    );
}

export default FileManager;