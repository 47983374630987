import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import listIcon from '@iconify/icons-eva/film-outline'
import codeIcon from '@iconify/icons-eva/code-outline'
import fileIcon from '@iconify/icons-eva/file-text-outline'
import { Icon } from '@iconify/react';
import { Avatar, Box, Button, Divider, IconButton, MenuItem, Typography } from '@mui/material';
// material
import { alpha } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// Auth context
import { useAuth } from 'src/contexts/AuthContext';
// components
import MenuPopover from '../../components/MenuPopover';
import api from '../../services/api';
import DefaultAvatar from './DefaultAvatar';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Home',
        icon: homeFill,
        linkTo: '/'
    },
    {
        label: 'Profile',
        icon: personFill,
        linkTo: '/profile'
    },
    {
        label: 'My Sessions',
        icon: fileIcon,
        linkTo: '/mySessions'
    },
    {
        label: 'Work Space',
        icon: codeIcon,
        linkTo: '/workspace'
    },
    {
        label: 'Offline Player',
        icon: listIcon,
        linkTo: '/offlinePlayer'
    },
    // {
    //     label: 'Settings',
    //     icon: settings2Fill,
    //     linkTo: '#'
    // }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const { user, setUser, setLoginStatus } = useAuth();
    const navigate = useNavigate();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const logout = () => {
        api.auth
            .logout()
            .then((res) => {
                if (res.data.success) {
                    setLoginStatus(false);
                    setUser({});
                    window.open('/', '_self');
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    return <>
        <IconButton
            ref={anchorRef}
            onClick={handleOpen}
            sx={{
                padding: 0,
                width: 44,
                height: 44,
                ...(open && {
                    '&:before': {
                        zIndex: 1,
                        content: "''",
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        position: 'absolute',
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                    }
                })
            }}
            size="large">
            {(user.profilePicture && <Avatar src={user.profilePicture} alt="photoURL" />) || (
                <DefaultAvatar user={user} />
            )}
        </IconButton>

        <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            sx={{ width: 220 }}
        >
            <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="subtitle1" noWrap>
                    {`${user.firstname} ${user.lastname}`}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                    {user.email}
                </Typography>
            </Box>

            <Divider sx={{ my: 1 }} />

            {MENU_OPTIONS.map((option) => (
                <MenuItem
                    key={option.label}
                    to={option.linkTo}
                    component={RouterLink}
                    onClick={handleClose}
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                >
                    <Box
                        component={Icon}
                        icon={option.icon}
                        sx={{
                            mr: 2,
                            width: 24,
                            height: 24
                        }}
                    />

                    {option.label}
                </MenuItem>
            ))}

            <Box sx={{ p: 2, pt: 1.5 }}>
                <Button onClick={logout} fullWidth color="inherit" variant="outlined">
                    Logout
                </Button>
            </Box>
        </MenuPopover>
    </>;
}
