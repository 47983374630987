import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetch = (url) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setIsLoading(true)
        const options = {
            url: process.env.REACT_APP_API_HOST + url,
            method: 'GET',
            withCredentials: true,
            timeout: 30000
        }
        axios(options)
            .then(data => {
                setData(data.data);
                setIsLoading(false);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    if (err.response) {
                        switch (err.response.status) {
                            case 404:
                                setError('Cannot find this session')
                                break;
                            case 400:
                                setError('This is a private session')
                                break;
                            case 500:
                                setError('Internal server error')
                                break;
                            default:
                                setError('Something went wrong')
                        }
                    } else {
                        setError(err.message);
                    }
                    setIsLoading(false);
                }
            });
    }, [url]);

    return [data, isLoading, error]
}

export default useFetch;