import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import TerminalUi from 'src/components/Terminal/TerminalUi'

const Preview = ({ files, height }) => {
    const [srcDoc, setSrcDoc] = useState('')
    const [renderingFile, setRenderingFile] = useState('')
    const [addressBarValue, setAddressBarValue] = useState("")
    const [logs, setLogs] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [showTerminal, setShowTerminal] = useState(false)
    const [title, setTitle] = useState('')

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickTerminal = (e) => {
        e.stopPropagation()
        setShowTerminal(!showTerminal)
        handleClose()
    }

    const reload = () => {
        setSrcDoc()
        setTimeout(() => {
            setSrcDoc(srcDoc)
        }, 1)
    }

    const onGotoClick = () => {
        setRenderingFile(addressBarValue || '')
    }

    const handleFormSubmit = e => {
        e.preventDefault()
        onGotoClick()
    }

    useEffect(() => {
        function listener(response) {
            // Make sure message is from our iframe, extensions like React dev tools might use the same technique and mess up our logs
            if (response.data && response.data.source === "iframe-console") {
                // Do whatever you want here.
                setLogs(logs => [...logs, { text: response.data.message.arguments.join(" "), isOutput: true, error: response.data.message.type === 'error' }])
            }
            if (response.data && response.data.source === "iframe-anchor") {
                setAddressBarValue(response.data.message)
                setRenderingFile(response.data.message)
            }
        }
        window.addEventListener("message", listener);

        return () => {
            window.removeEventListener("message", listener)
        }
    })

    useEffect(() => {
        const timeout = setTimeout(() => {
            const sanitizedFileName = renderingFile.replace(/^\/|\/$/, "") || "index.html"
            const indexHtml = files.find(file => file.key === sanitizedFileName || file.key === sanitizedFileName + "/index.html")
            if (!indexHtml) {
                setSrcDoc(`<h1>Could not find the page<h1>`)
                return
            }
            let xmlString = ""
            xmlString += Array.isArray(indexHtml.content) ? indexHtml.content.join('\n') : indexHtml.content || ""
            var doc = new DOMParser().parseFromString(xmlString, "text/html");

            doc.querySelectorAll("link").forEach(link => {
                const href = link.getAttribute("href")
                if (href) {
                    const matchedFile = files.find(file => href.includes(file.key)) || {}
                    const styleElement = doc.createElement('style')
                    styleElement.innerHTML = Array.isArray(matchedFile.content) ? matchedFile.content.join('\n') : matchedFile.content
                    doc.head.appendChild(styleElement)
                    link.remove()
                }
            })

            doc.querySelectorAll("a").forEach(a => {
                const href = a.getAttribute("href")
                if (href !== undefined) {
                    a.setAttribute('onclick', `onAnchorClick(event, '${href}');`)
                }
            })

            doc.querySelectorAll("script").forEach(script => {
                const src = script.getAttribute("src")
                if (src) {
                    const matchedFile = files.find(file => src.includes(file.key)) || {}
                    const scriptElement = doc.createElement('script')
                    scriptElement.innerHTML = Array.isArray(matchedFile.content) ? matchedFile.content.join('\n') : matchedFile.content
                    doc.body.appendChild(scriptElement)
                    script.remove()
                }
            })

            const scriptElement = doc.createElement('script')
            scriptElement.src = '/editor/script.js'
            doc.head.appendChild(scriptElement)

            setTitle(doc.title || sanitizedFileName)

            setSrcDoc(doc.documentElement.outerHTML)
            setLogs([])
        }, 250)
        return () => {
            clearTimeout(timeout)
        }
    }, [files, renderingFile])
    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <AppBar position="static">
                <Toolbar sx={{ minHeight: '40px !important' }}>
                    <Typography variant="span" component="div">
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Stack spacing={2} sx={{ height: height - 40 }}>

                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                    onSubmit={handleFormSubmit}
                >
                    <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={reload}>
                        <RefreshIcon />
                    </IconButton>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Type address"
                        inputProps={{ 'aria-label': 'ener file name' }}
                        value={addressBarValue}
                        onChange={(e) => { setAddressBarValue(e.target.value) }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={onGotoClick}>
                        <ArrowCircleRightIcon />
                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton
                        color="primary"
                        sx={{ p: '10px' }}
                        onClick={handleMenu}
                        aria-label="brower options"
                        aria-controls="menu-appbar"
                        aria-haspopup="true">
                        <MenuIcon />
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClickTerminal}>{showTerminal ? 'Close' : "Open"} Terminal</MenuItem>
                        </Menu>
                    </IconButton>
                </Paper>
                <iframe
                    src=""
                    frameBorder="0"
                    title="output"
                    width="100%"
                    height="100%"
                    srcDoc={srcDoc}
                    style={{ marginTop: 0 }}
                    ref={(ref) => {
                        if (!ref) return
                        ref.contentWindow.parentHost = window.location.host
                    }}
                />
                {showTerminal &&
                    <TerminalUi
                        disabled={false}
                        disableGreeting
                        lines={logs}
                    />
                }
            </Stack>
        </Box>

    );
}

export default Preview;