import React from "react";
import Ansi from "./AnsiToReact";
import Chalk from 'chalk';
const chalk = new Chalk.Instance({level: 3});

const TerminalInput = ({wd, children} : {children?: string, wd?: string}) => {
  const [cmd] = children?.split(' ')||[];
  const terminalCmd = children?.replace(cmd, chalk.yellowBright(cmd))

  return (
    <div className="react-terminal-line react-terminal-input" data-wd={wd+'>'}><Ansi>{ terminalCmd }</Ansi></div>
  );
} 

export default TerminalInput;