import invitationListdFill from '@iconify/icons-eva/browser-fill';
import meetingListdFill from '@iconify/icons-eva/calendar-fill';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import { Icon } from '@iconify/react';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const privateSidebarConfig = [
    {
        title: 'meetings',
        path: '/meetings',
        icon: getIcon(meetingListdFill)
    },
    {
        title: 'My Sessions',
        path: '/mySessions',
        icon: getIcon(pieChart2Fill)
    },
    {
        title: 'My Invitations',
        path: '/myInvitations',
        icon: getIcon(invitationListdFill)
    }
];

export default privateSidebarConfig;
