import { useEffect, useState, useRef } from "react";
// let audioEle = new Audio()

const useRecorder = (video, videoMedia) => {
    const [audioURL, setAudioURL] = useState("");
    const [recorderState, setRecorderState] = useState(null)
    const [streamStarted, setStreamStarted] = useState(false)

    const recorderRef = useRef(null)
    const streamRef = useRef(null)

    useEffect(() => {
        return () => {
            recorderRef.current?.removeEventListener("dataavailable", handleData);
            streamRef.current?.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    }, []);

    // useEffect(() => {
    //     if (listen) {
    //         console.log('listening')
    //         audioEle.play()
    //     } else {
    //         audioEle.pause()
    //     }
    // }, [listen])

    // Obtain the audio when ready.
    const handleData = e => {
        setAudioURL(URL.createObjectURL(e.data));
    };

    const startRecording = () => {
        prepareStream(video, videoMedia).then(() => {
            recorderRef.current.start();
            setRecorderState(recorderRef.current.state)
        });
    };

    const prepareStream = async (video, videoMedia) => {
        if (streamRef.current) {
            return
        }
        return requestRecorder(video, videoMedia).then(stream => {
            streamRef.current = stream
            recorderRef.current = new MediaRecorder(stream)

            // if ("srcObject" in audioEle) {
            //     audioEle.srcObject = stream
            // } else {
            //     console.log('no srcObject')
            //     audioEle.src = window.URL.createObjectURL(stream)
            // }

            recorderRef.current.addEventListener("dataavailable", handleData, { once: true });
            setStreamStarted(true)
        }, console.error);
    }

    const stopStream = () => {
        if (recorderRef.current.state !== 'recording' && recorderRef.current.state !== 'paused') {
            recorderRef.current?.removeEventListener("dataavailable", handleData);
            streamRef.current?.getTracks().forEach(function (track) {
                track.stop();
            });
            streamRef.current = null
            setStreamStarted(false)
        }
    }

    const pauseRecording = () => {
        recorderRef.current.pause();
        setRecorderState(recorderRef.current.state)
    };

    const resumeRecording = () => {
        recorderRef.current.resume();
        setRecorderState(recorderRef.current.state)
    };

    const stopRecording = () => {
        recorderRef.current.stop();
        setRecorderState(recorderRef.current.state)
        recorderRef.current.removeEventListener("dataavailable", handleData);
        streamRef.current.getTracks().forEach(function (track) {
            track.stop();
        });
    };

    return [audioURL, recorderState, startRecording, pauseRecording, resumeRecording, stopRecording, prepareStream, stopStream, streamRef, streamStarted];
};

async function requestRecorder(video, videoMedia) {
    const userMediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: video && videoMedia === 'CAMERA' });
    if (!video || videoMedia === 'CAMERA') {
        return userMediaStream
    }

    const displayMediaStream = await navigator.mediaDevices.getDisplayMedia({
        video: true
    });

    return new MediaStream([...displayMediaStream.getTracks(), ...userMediaStream.getTracks()]);
}
export default useRecorder;