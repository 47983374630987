const applyDelta = ([...original], delta) => {
    if (delta) {
        original.splice(delta.from, delta.removeLines, ...delta.newLines)
    }
    return original
}

// const defaultFiles = [
//     {
//         key: 'src/App.js',
//         content: []
//     },
//     {
//         key: 'src/app.css',
//         content: []
//     },
//     {
//         key: 'src/index.js',
//         content: []
//     },
//     {
//         key: 'src/index.css',
//         content: []
//     },
//     {
//         key: 'index.js',
//         content: []
//     },
//     {
//         key: 'app.css',
//         content: []
//     },
//     // {
//     //     key: 'index.html',
//     //     content: []
//     // },
// ]

const defaultFiles = []

export {
    applyDelta,
    defaultFiles
}