import { useState, useEffect, useRef } from 'react';

import VideoContainer from 'src/components/videoContainer';

export default function Video({ open, audioEle, onClose, videoMaxHeight, fullscreen, onFullscreenClick, loading }) {
    const [minimized, setMinimized] = useState(false)

    const videoRef = useRef(null)

    useEffect(() => {
        if (minimized) return
        const canvas = videoRef.current
        const ctx = canvas.getContext("2d");
        const video = audioEle.current;
        const width = video.videoWidth
        const height = video.videoHeight
        let render = true

        function step() {
            var hRatio = canvas.width / width;
            var vRatio = canvas.height / height;
            var ratio = Math.min(hRatio, vRatio);
            var centerShift_x = (canvas.width - width * ratio) / 2;
            var centerShift_y = (canvas.height - height * ratio) / 2;
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            if (video.paused && video.currentTime === 0) {
                video.currentTime = 0.001
            }
            ctx.drawImage(video, 0, 0, width * 1, height * 1, centerShift_x, centerShift_y, width * ratio, height * ratio);

            if (render) requestAnimationFrame(step);
        }
        requestAnimationFrame(step);

        return () => {
            render = false
        }
    }, [minimized])

    useEffect(() => {
        if (!videoRef.current) return
        videoRef.current.height = videoRef.current.clientHeight
        videoRef.current.width = videoRef.current.clientWidth
    }, [fullscreen, minimized])

    return (
        <VideoContainer
            open={open} onClose={onClose} videoMaxHeight={videoMaxHeight} fullscreen={fullscreen} onFullscreenClick={onFullscreenClick} minimized={minimized} setMinimized={setMinimized} loading={loading}
        >
            <canvas style={{ width: fullscreen ? "100%" : "320px", height: fullscreen ? "100%" : "240px" }} ref={videoRef}></canvas>
        </VideoContainer>
    );
}