import React from 'react';
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

const CoverImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
});

function Loader({ thumbnail, error }) {

    const onRef = ref => {
        if (!ref) return
        ref.style.height = `calc(100vh - ${ref.offsetTop}px)`
    }

    return (
        <Box ref={onRef} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
            <CoverImgStyle alt="thumbnail" src={thumbnail} />
            {error ? <Box sx={{ zIndex: 2 }}><Alert severity="error">{error}</Alert></Box> : <CircularProgress />}

        </Box>
    );
}

export default Loader;
