import {
    useState,
    useEffect,
    useRef,
    KeyboardEvent,
    ChangeEvent,
    ReactNode,
    forwardRef
} from 'react';
import TerminalInput from './linetypes/TerminalInput';
import TerminalOutput from './linetypes/TerminalOutput';
import TerminalInputLine from './linetypes/TerminalInputLine';
import TerminalGreeting from './linetypes/TerminalGreeting';
import './style.css';

export enum ColorMode {
    Light,
    Dark
}

export interface Props {
    lines: any[];
    name?: string;
    prompt?: string;
    colorMode?: ColorMode;
    children?: ReactNode;
    onInput?: ((input: string) => void) | null | undefined;
    onInputChange?: ((input: string) => void) | null | undefined;
    onTerminate?: (() => void) | null | undefined;
    startingInputValue?: string;
    cmdRunning?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    lineInput?: string;
    disableGreeting?: boolean;
}

export type Ref = HTMLDivElement;

const TerminalUi = forwardRef<Ref, Props>(
    (
        {
            lines = [],
            name = 'CodeLens terminal',
            prompt,
            colorMode,
            onInput,
            onInputChange,
            onTerminate,
            children,
            startingInputValue = '',
            cmdRunning,
            disabled = false,
            readonly = false,
            lineInput,
            disableGreeting = false
        }: Props,
        ref
    ) => {
        const [currentLineInput, setCurrentLineInput] = useState('');

        const scrollIntoViewRef = useRef<HTMLDivElement>(null);
        const commandsRef = useRef<string[]>([]);
        const currentCommandsRef = useRef<number>(-1);

        const updateCurrentLineInput = (event: ChangeEvent<HTMLInputElement>) => {
            if (readonly) return;
            setCurrentLineInput(event.target.value);
            onInputChange && onInputChange(event.target.value);
        };

        const handleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
            if (onInput == null) return;
            if (event.key === 'Enter') {
                onInput(currentLineInput);
                commandsRef.current.unshift(currentLineInput);
                setCurrentLineInput('');
            } else if (event.key === 'ArrowUp') {
                if (currentCommandsRef.current + 1 < commandsRef.current.length) {
                    currentCommandsRef.current += 1;
                    setCurrentLineInput(commandsRef.current[currentCommandsRef.current]);
                }
            } else if (event.key === 'ArrowDown') {
                if (currentCommandsRef.current > 0) {
                    currentCommandsRef.current -= 1;
                    setCurrentLineInput(commandsRef.current[currentCommandsRef.current]);
                }
            }
        };

        const handleTerminalKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
            if (onTerminate == null || !cmdRunning) return;
            if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
                onTerminate();
            }
        };

        const scrollIntoView = () => {
            scrollIntoViewRef?.current?.scrollIntoView({
                behavior: 'smooth'
                // block: 'nearest'
            });
        };

        useEffect(() => {
            setCurrentLineInput(startingInputValue.trim());
        }, [startingInputValue]);

        useEffect(() => {
            if (lineInput === null || lineInput === undefined) return;
            setCurrentLineInput(lineInput);
        }, [lineInput]);

        useEffect(() => {
            setTimeout(scrollIntoView, 1);
        }, [lines, currentLineInput]);

        // We use a hidden input to capture terminal input; make sure the hidden input is focused when clicking anywhere on the terminal
        useEffect(() => {
            if (onInput == null) {
                return;
            }
            // keep reference to listeners so we can perform cleanup
            const elListeners: {
                terminalEl: Element;
                listener: EventListenerOrEventListenerObject;
            }[] = [];
            for (const terminalEl of document.getElementsByClassName('react-terminal-wrapper')) {
                const listener = () =>
                    (terminalEl?.querySelector('.terminal-hidden-input') as HTMLElement)?.focus();
                terminalEl?.addEventListener('click', listener);
                elListeners.push({ terminalEl, listener });
            }
            return function cleanup() {
                elListeners.forEach((elListener) => {
                    elListener.terminalEl.removeEventListener('click', elListener.listener);
                });
            };
        }, [onInput]);

        const classes = ['react-terminal-wrapper'];
        if (colorMode === ColorMode.Light) {
            classes.push('react-terminal-light');
        }
        return (
            <div
                className={classes.join(' ')}
                data-terminal-name={name}
                onKeyDown={handleTerminalKeydown}
                ref={ref}
            >
                <div className="react-terminal">
                    {!disableGreeting && <TerminalGreeting />}
                    {lines.map(({ isOutput, text, ...line }, id) => {
                        if (isOutput) {
                            return (
                                <TerminalOutput key={id} {...line}>
                                    {text}
                                </TerminalOutput>
                            );
                        } else {
                            return (
                                <TerminalInput key={id} {...line}>
                                    {text}
                                </TerminalInput>
                            );
                        }
                    })}
                    {disabled && <TerminalOutput>Terminal Disabled</TerminalOutput>}
                    {(onInput || readonly) && !disabled && (
                        <TerminalInputLine
                            prompt={cmdRunning ? '' : prompt + '>' || '$'}
                            styleInput={!cmdRunning}
                        >
                            {currentLineInput}
                        </TerminalInputLine>
                    )}
                    <div ref={scrollIntoViewRef}></div>
                </div>
                <input
                    className="terminal-hidden-input"
                    placeholder="Terminal Hidden Input"
                    value={currentLineInput}
                    autoFocus={onInput != null}
                    onChange={updateCurrentLineInput}
                    onKeyDown={handleEnter}
                />
            </div>
        );
    }
);

export { TerminalInput, TerminalOutput };
export default TerminalUi;
