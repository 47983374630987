import { useState, useRef, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Player from 'src/components/Player';
import useFetch from 'src/hooks/useFetch';
import axios from 'axios';
import {
    Stack,
    Container,
    Typography,
    Alert,
    Box
} from '@mui/material';
import Loader from 'src/components/Loader/Loader';
import SessionDescription from './SessionDescription';
import CommentSection from 'src/components/CommentSection/CommentsSection';
import Footer from 'src/components/Footer';

import { Helmet } from 'react-helmet-async';

const ViewSession = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();

    const baseDirectory = process.env.REACT_APP_DATA_PATH + id
    const records = useRef({ records: [], activeFileRecords: [], actionRecords: { cursor: [], scroll: [], selection: [] } })
    const [session, isLoading, error] = useFetch((searchParams.get("author") ? '/sessions/my/' : '/sessions/') + id);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const options = {
            url: process.env.REACT_APP_API_HOST + baseDirectory + '-records.json',
            method: 'GET',
            withCredentials: true,
            timeout: 30000
        };
        setLoading(true)
        axios(options).then((data) => {
            // setRecords(data.data);
            records.current = data.data
            setLoading(false)
        });
        // .catch(err => {
        //     if (err.name === 'AbortError') {
        //         console.log('Fetch aborted');
        //     } else {
        //         setIsLoading(false);
        //         setError(err.message);
        //     }
        // });

    }, [id]);

    if (isLoading) return <Loader />
    if (error) {
        return (
            <Container>
                <Helmet>
                    <title>CodeLens | Error</title>
                </Helmet>
                <Alert severity="error">{error}</Alert>
            </Container>
        )
    }
    const audioURL = process.env.REACT_APP_API_HOST + baseDirectory + (session.mediaType === 'video' ? '-video.mp4' : '-audio.mp3')
    const thumbnail = process.env.REACT_APP_API_HOST + baseDirectory + "-thumbnail.png"
    return (
        <>
            <Helmet>
                <title>CodeLens | {session.title || ''}</title>
            </Helmet>
            <Player session={session} records={records} audioURL={audioURL} loading={isLoading || loading} thumbnail={thumbnail} />
            <SessionDescription session={session} />
            <CommentSection />
            <Footer />
        </>
    );
};

export default ViewSession;
