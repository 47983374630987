import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
// material
import {
    Avatar,
    Button, Container, Stack, Typography
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import DataTable from 'src/components/DataTable/DataTable';
import Label from 'src/components/Label';
// components
import Page from 'src/components/Page';
import api from 'src/services/api';


const MySessions = () => {
    const [values, setValues] = useState([])
    const columns = [
        {
            id: 'title', label: 'Title', alignRight: false, props: { component: "th", scope: "row", padding: "none" }, render: (item, id) => (
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar alt={item.title} src={item.title} />
                    <Typography variant="subtitle2" noWrap>
                        {item.title}
                    </Typography>
                </Stack>
            )
        },
        {
            id: 'description', label: 'Description', alignRight: false, render: (item, id) => (
                <div style={{ width: '400px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {item.description}
                </div>
            )
        },
        {
            id: 'duration', label: 'Duration', alignRight: false, render: (item, id) => (
                parseFloat(item.duration).toFixed(2)
            )
        },
        {
            id: 'published', label: 'Status', alignRight: false, render: (item, id) => (
                <Label
                    variant="ghost"
                    color={item.published ? 'success' : 'error'}
                >
                    {sentenceCase(item.published ? 'Published' : 'Private')}
                </Label>
            )
        },
        {
            id: 'action', label: 'Action', alignRight: false, render: (item, id) => (
                <Button
                    variant="contained"
                    color={item.published ? 'error' : 'primary'}
                    onClick={() => handleToggle(item)}
                >
                    {item.published ? 'Make private' : 'Publish session'}
                </Button>
            )
        },

    ];

    const handleToggle = (item) => {
        api.sessions.toggleStatus({ id: item._id, published: !item.published })
            .then(({ data }) => {
                if (data.status) {
                    setValues(values => values.map(value => {
                        if (value._id === item._id) {
                            value.published = !item.published;
                        }
                        return value
                    }))
                }
            })
    }

    const handleDelete = (item) => {
        api.sessions.delete(item._id)
            .then(({ data }) => {
                if (data.status) {
                    setValues(values => values.filter(value => value._id !== item._id))
                }
            })
    }

    useEffect(() => {
        api.sessions.getMy()
            .then(({ data }) => {
                setValues(data)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <Page title="CodeLens | My Sessions" sx={{ pb: 2 }}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Sessions
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        color="primary"
                        to="/mySessions/record"
                        startIcon={<Icon icon={plusFill} />}
                        sx={{
                            color: 'white !important',
                            "&:hover": {
                                color: 'white !important',
                            },
                        }}
                    >
                        Record session
                    </Button>
                </Stack>
                <DataTable
                    columns={columns}
                    values={values}
                    editRoute={(item, id) => `/mySessions/edit/${item._id}`}
                    viewRoute={(item, id) => `/sessions/${item._id}?author=1`}
                    onDelete={handleDelete}
                />
            </Container>
        </Page>
    );
}

export default MySessions;