import React, { createContext, useContext, useEffect, useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
import api from 'src/services/api';

const loading = (
    <Box sx={{ width: '100%' }}>
        <LinearProgress sx={{ height: '5px' }} />
    </Box>
);

const notFound = (
    <div className="pt-3 text-center min-vh-100">
        <div className="sk-spinner sk-spinner-pulse">
            <h3>No Account found</h3>
        </div>
    </div>
);

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState();
    const [loginStatus, setLoginStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { getUser } = api.auth;

    useEffect(() => {
        getUser()
            .then(({ data }) => {
                if (data) {
                    setUser(data);
                    setLoginStatus(true);
                } else {
                    setUser({});
                    setLoginStatus(false);
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setUser({});
                setLoginStatus(false);
                setIsLoading(false);
            });
    }, []);

    const value = {
        user,
        loginStatus,
        setUser,
        setLoginStatus
    };

    return (
        <AuthContext.Provider value={value}>{isLoading ? loading : children}</AuthContext.Provider>
    );
}
