import { useState, useEffect, useRef } from 'react';
import {
    Paper,
    Fab
} from '@mui/material';
import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Draggable from 'react-draggable';

import Close from '@mui/icons-material/Close'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import MinimizeIcon from '@mui/icons-material/Minimize';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import style from './style.module.css'

export default function VideoContainer({ open, onClose, videoMaxHeight, fullscreen, onFullscreenClick, minimized, setMinimized, disableClose = false, loading = false, children }) {
    const defaultPosition = { x: document.body.clientWidth / 2, y: 100 };

    const lastPositionRef = useRef()

    const [position, setPositionState] = useState(null)

    const setPosition = ({ x, y }) => {
        const limitted_x = x > document.body.clientWidth - 336 ? document.body.clientWidth - 336 : x < 0 ? 0 : x
        const videoContHeight = minimized ? 41 : 256
        const limitted_y = y > document.body.clientHeight - videoContHeight ? document.body.clientHeight - videoContHeight : y < 0 ? 0 : y
        setPositionState({ x: limitted_x, y: limitted_y })
    }

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleDrag = (e, data) => {
        !fullscreen && setPosition({ x: data.x, y: data.y })
    }

    const handleMinimizeClick = () => {
        if (fullscreen) onFullscreenClick()
        setMinimized(minimized => !minimized)
    }

    const handleVideoClick = (e) => {
        if (e.detail === 2) {
            onFullscreenClick()
        }
    }

    useEffect(() => {
        if (fullscreen) {
            lastPositionRef.current = { ...position }
            setPosition({ x: 0, y: 0 })
        } else {
            setPosition(lastPositionRef.current || defaultPosition)
        }
    }, [fullscreen])

    if (!open) return null

    const paperStyles = fullscreen ? { height: videoMaxHeight, width: document.body.clientWidth, p: 0 } : minimized ? { display: "flex", justifyContent: 'flex-end', width: 320 } : {}

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', zIndex: 10, height: 0 }}>
                <Draggable defaultPosition={defaultPosition} position={position} onDrag={handleDrag} cancel={`.${style.mobile}`}>
                    <Paper elevation={5} sx={{ p: 1, ...paperStyles }} className={`${style.videoCont} ${fullscreen ? style.fullscreen : ""}`} onClick={handleVideoClick}>
                        {minimized
                            ? <Fab color="primary" aria-label="add" className={style.maxBtn} onClick={handleMinimizeClick} >
                                <OpenInFullIcon fontSize="small" fontWeight="bolder" />
                            </Fab>
                            :
                            <>
                                {children}
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={loading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>

                                <Fab color="primary" aria-label="add" className={`${style.minBtn} ${mobile ? style.mobile : ''}`} onClick={handleMinimizeClick} >
                                    <MinimizeIcon fontSize="large" fontWeight="bolder" />
                                </Fab>
                                <Fab color="primary" aria-label="add" className={`${style.actionBtn} ${mobile ? style.mobile : ''}`} onClick={onClose} disabled={disableClose} >
                                    <Close fontSize="large" fontWeight="bolder" />
                                </Fab>
                                <Fab color="primary" aria-label="add" className={`${style.fullscreenBtn} ${mobile ? style.mobile : ''}`} onClick={onFullscreenClick}  >
                                    {fullscreen ? <FullscreenExitIcon fontSize="large" fontWeight="bolder" /> : <FullscreenIcon fontSize="large" fontWeight="bolder" />}
                                </Fab>
                            </>
                        }

                    </Paper>
                </Draggable>
            </div>
        </div>
    );
}