import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

//icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://codelensapp.com/">
                CodeLens
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Links() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
            <Stack spacing={4} direction='row'>
                <Link color="inherit" component={RouterLink} to="/" sx={{ textDecoration: 'none' }}>
                    Home
                </Link>
                <Link color="inherit" component={RouterLink} to="/about" sx={{ textDecoration: 'none' }}>
                    About
                </Link>
                <Link color="inherit" component={RouterLink} to="/workspace" sx={{ textDecoration: 'none' }}>
                    Work Space
                </Link>
            </Stack>
        </Box>
    )
}

function SocialMedia() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
            <Stack spacing={2} direction='row'>
                <Link color="inherit" href="https://codelensapp.com/">
                    <FacebookIcon />
                </Link>
                <Link color="inherit" href="https://codelensapp.com/">
                    <TwitterIcon />
                </Link>
            </Stack>
        </Box>
    )
}

function Footer(props) {

    return (
        <Box component="footer" sx={{ bgcolor: 'background.paper', py: 3 }}>
            <Container maxWidth="lg">
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <Box component="img" src="/static/logo.png" sx={{ width: 100 }} />
                </Box>
                {/* <Typography variant="h6" align="center" gutterBottom>
                    CodeLens
                </Typography> */}
                {/* <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    Learn to code with our interactive video tutorials!
                </Typography> */}
                <Links />
                <SocialMedia />
                <Copyright />
            </Container>
        </Box>
    );
}

export default Footer;