import { useState, useEffect, useRef, forwardRef } from 'react';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/foldgutter.css';
import './Editor.css';
// import 'codemirror/'
import { Controlled as ControlledEditor } from 'react-codemirror2';
import { Box } from '@mui/material';
import {
    Maximize2 as Maximize2Icon,
    Minimize2 as Minimize2Icon,
    X as CloseIcon
} from 'react-feather';
import { minHeight } from '@mui/system';

const Editor = forwardRef(({ value, onChange, file, propLanguage, edit, actions: { onCursor, onScroll, onSelection } = {}, controls: { cursor, scroll, selection } = {}, closeCurrentFile }, ref) => {
    const [open, setOpen] = useState(true);
    const editorRef = useRef(null)

    useEffect(() => {
        if (!editorRef.current) return
        editorRef.current.setCursor(cursor)
    }, [cursor])

    useEffect(() => {
        if (!editorRef.current) return
        editorRef.current.scrollTo(scroll.x, scroll.y + editorRef.current.offsetTop)
    }, [scroll])

    useEffect(() => {
        if (!editorRef.current) return
        editorRef.current.setSelections(selection)
    }, [selection])

    const extension = file?.key.split(".")[1]
    let language = propLanguage || 'javascript'
    if (extension) {
        switch (extension) {
            case 'html':
                language = 'xml'
                break;
            case 'js':
                language = 'javascript'
                break;
            case 'css':
                language = 'css'
                break;
        }
    }

    const handleChange = (editor, data, value) => {
        if (edit) {
            const newLines = value.split('\n').slice(data.from.line, data.from.line + data.text.length)
            onChange(value, { from: data.from.line, removeLines: data.to.line - data.from.line + 1, newLines });
        } else {
            data.cancel()
        }
        //onChange(data)
    };
    let name = '';
    if (file.key) {
        name = file.key;
        const slashIndex = name.lastIndexOf('/');
        if (slashIndex !== -1) {
            name = name.substr(slashIndex + 1);
        }
    }

    return (
        <Box p={0} sx={{ height: '100%' }} ref={ref}>
            <Box
                style={{
                    backgroundColor: 'rgba(38, 50, 56, 0.79)',
                    color: 'yellow',
                    padding: '0 10px',
                    fontWeight: '200',
                    justifyContent: "space-between",
                    minHeight: 30,
                    display: 'flex'
                }}
            >
                {file.key && <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ margin: 'auto', marginLeft: 10 }}>{name}</span>
                    <CloseIcon size={15} style={{ marginLeft: 10, color: '#fff', cursor: 'pointer' }} onClick={closeCurrentFile} />
                </Box>}
            </Box>
            {file.key ? (
                <ControlledEditor
                    onBeforeChange={handleChange}
                    // onChange={(editor, data, value) => {
                    //     // console.log(data)
                    // }}
                    editorDidMount={(editor) => {
                        // setEditor(editor);
                        editorRef.current = editor
                    }}
                    onCursor={(editor, cur) => onCursor && onCursor(cur)}
                    onScroll={(editor, scroll) => onScroll && onScroll({ x: scroll.left, y: scroll.top })}
                    onSelection={(editor, selection) => onSelection && onSelection(selection.ranges)}
                    value={(value || []).join('\n')}
                    className="code-mirror-wrapper"
                    cursor={cursor?.line ? cursor : { line: 0, ch: 0 }}
                    scroll={scroll || { x: 0, y: 0 }}
                    selection={selection || { ranges: [], focus: true }}
                    // onRenderLine={(cm, line, element) => {
                    //     console.log({ cm, line, element })
                    // }}
                    options={{
                        linerWrapping: true,
                        lint: true,
                        mode: language,
                        theme: 'material',
                        lineNumbers: true,
                        autoCloseBrackets: true,
                        matchBrackets: true,
                        foldGutter: true,
                        smartIndent: true,
                        tabindex: 4,
                        tabSize: 4,
                        gutters: [{ className: 'play', style: 'width:10px' }, "CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                        autoCloseTags: true,
                        extraKeys: {
                            "Ctrl-Space": "autocomplete",
                        }
                    }}
                    onGutterClick={(editor, line, gutter, e) => {
                        console.log(line)
                        var info = editor.lineInfo(line);
                        editor.setGutterMarker(line, 'play', info.gutterMarkers ? null : document.createTextNode(">"))
                    }}
                />
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="CodeMirror noFile">
                    <Box component="img" src="/static/logo-only.png" sx={{ width: 200 }} />
                </Box>
            )}
        </Box>
    );
});

export default Editor;
