import { useEffect, useState } from 'react';
import axios from 'axios';

const useIntervalFetch = (url, interval = 1000) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    const abortCont = new AbortController();

    const sendRequest = () => {
        axios({
            url: process.env.REACT_APP_API_HOST + url,
            method: 'GET',
            withCredentials: true,
            timeout: 30000
        })
            .then((res) => {
                if (res.status !== 200 && res.status !== 304) {
                    throw Error('could not fetch data');
                }
                return res.data;
            })
            .then((data) => {
                setData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    setIsLoading(false);
                    setError(err.message);
                }
            });
    };

    useEffect(() => {
        sendRequest();
        const intervalId = setInterval(sendRequest, interval);
        return () => {
            clearInterval(intervalId);
            abortCont.abort();
        };
    }, [url]);

    return { data, isLoading, error };
};

export default useIntervalFetch;
