import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import success from './success.gif'

const UploadProgress = ({ open, uploaded = 0, completed }) => {
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                {completed ? "Session uploaded" : "Uploading session"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {completed ? "Session uploaded" : "Recorded session is being uploaded."}
                </DialogContentText>

                {!completed ?
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress variant={uploaded == 100 ? "indeterminate" : "determinate"} value={uploaded} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <Typography variant="body2" color="text.secondary">{`${Math.round(
                                uploaded,
                            )}%`}</Typography>
                        </Box>
                    </Box>
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <img style={{ width: '50%' }} src={success} />
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button disabled>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadProgress;