import React from 'react';
import {
    Box,
    Link,
    Button,
    Drawer,
    Typography,
    Avatar,
    Stack,
    Container,
    Grid,
    TextField,
    InputAdornment
} from '@mui/material';

import ProfilePictureEdit from './ProfilePictureEdit';
import { useAuth } from 'src/contexts/AuthContext';

const styles = {
    profilePic: {
        height: '80%',
        width: '80%',
        borderRadius: '50%',
        border: '5px',
        borderStyle: 'solid',
        borderColor: '#555'
    }
};

function ProfilePicture() {
    const { user } = useAuth();
    const { profilePicture } = user;
    return (
        <Stack display="flex" flexDirection="column" alignItems="center" spacing={3}>
            <Typography variant="subtitle1" noWrap>
                Profile Picture
            </Typography>
            {(profilePicture && (
                <img style={styles.profilePic} src={profilePicture} alt="profile_picture" />
            )) || (
                <img
                    style={styles.profilePic}
                    src="/static/default_profile_avatar.png"
                    alt="profile_picture"
                />
            )}
            <ProfilePictureEdit />
        </Stack>
    );
}

export default ProfilePicture;
