import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import {
    Maximize2 as Maximize2Icon,
    Minimize2 as Minimize2Icon
} from 'react-feather';

const expandStyle = { margin: 'auto', marginRight: 10, cursor: 'pointer', border: '1px solid white', padding: 4, borderRadius: 4 }

const menus = [
    {
        label: 'File',
        id: 'file',
        items: [
            { label: 'New', id: 'new', desktop: true },
            { label: 'Open', id: 'open', desktop: true },
            { label: 'Save', id: 'save', desktop: true },
            { label: 'Save As', id: 'save-as', desktop: true },

            { label: 'New', id: 'web-new', web: true },
            { label: 'Open', id: 'web-open', web: true },
            { label: 'Save', id: 'web-save', web: true },
        ]
    },
    {
        label: 'View',
        id: 'view',
        items: [
            { label: 'Preview', id: 'preview' },
            { label: 'Terminal', id: 'terminal' },
            { divider: true, id: 'divider' },
            { label: 'Local Terminal', id: 'local-terminal' },
            { label: 'Player Terminal', id: 'player-terminal' },
        ]
    },
]

const MenuBar = ({ onMenuClick, preview, onPreviewToggle }) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = menu => {
        onMenuClick(menu)
        handleClose()
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar sx={{ minHeight: 0 }} disableGutters variant="dense">
                    {menus.reduce((final, menu) => {
                        const open = !!(anchorEl && anchorEl.id === ('editor-menu-' + menu.label))
                        final = [
                            ...final,
                            <Button
                                key={menu.id + '0'}
                                size="small"
                                color="inherit"
                                id={'editor-menu-' + menu.label}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                sx={{ fontWeight: 100 }}
                            >
                                {menu.label}
                            </Button>,
                            <Menu
                                key={menu.id + '1'}
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {menu.items.map(menuItem => {
                                    if (menuItem.divider) {
                                        <Divider key={menu.id + "-" + menuItem.id} />
                                    } else if ((!menuItem.desktop && !menuItem.web) || (menuItem.desktop && !!window.electronAPI) || (menuItem.web && !window.electronAPI)) {
                                        return <MenuItem key={menu.id + "-" + menuItem.id} sx={{ minWidth: 200 }} onClick={() => handleMenuClick(menu.id + "-" + menuItem.id)}>{menuItem.label}</MenuItem>
                                    } else return null
                                })}
                            </Menu>

                        ];
                        return final
                    }, [])}
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={onPreviewToggle}
                    >
                        {preview ? <Maximize2Icon size={20} style={expandStyle} /> : <Minimize2Icon size={20} style={expandStyle} />}
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default MenuBar;