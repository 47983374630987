import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { makeStyles } from '@mui/styles';
import {
    Modal,
    Button,
    Card,
    CardContent,
    Typography,
    TextField,
    IconButton,
    InputAdornment
} from '@mui/material';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Backdrop from '@mui/material/Backdrop';

import api from 'src/services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        maxWidth: 450
    },
    title: {
        fontSize: 14
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

function PasswordChange(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [showcurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordAgain, setShowNewPasswordAgain] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertServerity, setAlertServerity] = useState('success');
    const [isDisabledSend, setDisableSend] = useState(false);

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            newPasswordAgain: ''
        },
        validationSchema: Yup.object().shape({
            currentPassword: Yup.string().required('Cuurent Password is required'),
            newPassword: Yup.string().required('Enter Your New Password is required'),
            newPasswordAgain: Yup.string().required('Enter Your New Password Again')
        }),
        onSubmit: (initialValues, actions) => {
            if (initialValues.newPassword === initialValues.newPasswordAgain) {
                console.log(actions);
                actions.setSubmitting(true);
                api.users
                    .passwordChange({
                        currentPassword: initialValues.currentPassword,
                        newPassword: initialValues.newPassword
                    })
                    .then((res) => {
                        if (res.data.success) {
                            actions.setSubmitting(false);
                            setDisableSend(true);
                            setAlertMsg(res.data.msg);
                            setAlertServerity('success');
                            setShowAlert(true);
                            actions.resetForm();
                        } else {
                            setAlertMsg(res.data.msg);
                            setAlertServerity('error');
                            setShowAlert(true);
                            actions.setSubmitting(false);
                        }
                    })
                    .catch((err) => {
                        actions.setSubmitting(false);
                        console.log(err);
                    });
            } else {
                actions.setFieldError(
                    'newPasswordAgain',
                    'Both entered new passwords must be same'
                );
                actions.setSubmitting(false);
            }
        }
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const hideAlert = () => {
        setShowAlert(false);
        setAlertMsg('');
        setAlertServerity('success');
    };

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <div>
            <Button sx={{ m: 1 }} onClick={handleOpen} variant="contained" color="primary">
                Password Change
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Card sx={{ m: 1 }} className={classes.root}>
                            <CardContent>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                >
                                    Please enter your current password and new password below.
                                </Typography>
                                {showAlert && (
                                    <Alert
                                        sx={{ m: 1 }}
                                        onClose={() => hideAlert()}
                                        variant="filled"
                                        severity={alertServerity}
                                    >
                                        {alertMsg}
                                    </Alert>
                                )}
                                <TextField
                                    sx={{ m: 1 }}
                                    fullWidth
                                    autoComplete="current-password"
                                    type={showcurrentPassword ? 'text' : 'password'}
                                    label="Current Password"
                                    {...getFieldProps('currentPassword')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={() =>
                                                        setShowCurrentPassword((prev) => !prev)
                                                    }
                                                    size="large">
                                                    <Icon
                                                        icon={
                                                            showcurrentPassword
                                                                ? eyeFill
                                                                : eyeOffFill
                                                        }
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={Boolean(
                                        touched.currentPassword && errors.currentPassword
                                    )}
                                    helperText={touched.currentPassword && errors.currentPassword}
                                />
                                <TextField
                                    sx={{ m: 1 }}
                                    fullWidth
                                    autoComplete="current-password"
                                    type={showNewPassword ? 'text' : 'password'}
                                    label="New Password"
                                    {...getFieldProps('newPassword')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={() =>
                                                        setShowNewPassword((prev) => !prev)
                                                    }
                                                    size="large">
                                                    <Icon
                                                        icon={
                                                            showNewPassword ? eyeFill : eyeOffFill
                                                        }
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={Boolean(touched.newPassword && errors.newPassword)}
                                    helperText={touched.newPassword && errors.newPassword}
                                />

                                <TextField
                                    sx={{ m: 1 }}
                                    fullWidth
                                    autoComplete="current-password"
                                    type={showNewPasswordAgain ? 'text' : 'password'}
                                    label="Confirm New Password"
                                    {...getFieldProps('newPasswordAgain')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={() =>
                                                        setShowNewPasswordAgain((prev) => !prev)
                                                    }
                                                    size="large">
                                                    <Icon
                                                        icon={
                                                            showNewPasswordAgain
                                                                ? eyeFill
                                                                : eyeOffFill
                                                        }
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={Boolean(
                                        touched.newPasswordAgain && errors.newPasswordAgain
                                    )}
                                    helperText={touched.newPasswordAgain && errors.newPasswordAgain}
                                />
                                <LoadingButton
                                    sx={{ m: 1 }}
                                    disabled={isDisabledSend}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Submit
                                </LoadingButton>
                            </CardContent>
                        </Card>
                    </Form>
                </FormikProvider>
            </Modal>
        </div>
    );
}

export default PasswordChange;
