import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Player from 'src/components/Player'
import useFetch from 'src/hooks/useFetch';
import {
    Stack,
    Container,
    Typography,
    Box
} from '@mui/material';

const ViewMySession = () => {
    const { id } = useParams()
    const [data, isLoading] = useFetch('/sessions/my/' + id)
    return (
        <>
            <Box sx={{ backgroundColor: 'rgba(200, 200, 200, 1)' }}>
                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>
                        <Typography variant="h4" gutterBottom>
                            {data.title}
                        </Typography>
                    </Stack>
                </Container>
            </Box>
            <Player
                baseDIrectory={'/uploads/' + id}
            />
        </>
    );
}

export default ViewMySession;