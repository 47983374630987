import React from 'react';
import { TextField } from '@mui/material';
import { useAuth } from 'src/contexts/AuthContext';

function EmailAddressEdit() {
    const { user } = useAuth();
    const { email } = user;
    return (
        <div>
            <TextField
                sx={{ m: 1, marginTop: 4 }}
                fullWidth
                label="Email Address"
                variant="outlined"
                defaultValue={email}
                disabled
            />
            {/* <Button sx={{ marginLeft: 1 }} type="submit" variant="contained" color="primary">
                Change Email Address
            </Button> */}
        </div>
    );
}

export default EmailAddressEdit;
