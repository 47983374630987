import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import Tv from '@mui/icons-material/Tv'
import TvOff from '@mui/icons-material/TvOff'
import Flow from '@mui/icons-material/Label'
import FlowOff from '@mui/icons-material/LabelOff'
import FileDownload from '@mui/icons-material/GetApp'

import Video from '../Video'

const Widget = styled('div')(({ theme }) => ({
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: theme.spacing(2),
    width: '100%',
    maxWidth: '100%',
    margin: 'auto',
    position: 'relative',
    // zIndex: 1,
    backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.4)',
    backdropFilter: 'blur(40px)',
}));

const TinyText = styled(Typography)({
    fontSize: '0.75rem',
    opacity: 0.38,
    fontWeight: 500,
    letterSpacing: 0.2,
});

export default function SessionControls({ play, handleAudioChange, audioEle, duration, session, audioURL, flow, handleFlowChange, handleDownloadCurrent, handleDownloadFinal, handleDownloadSession, videoOpen, handleVideoClick }) {
    const theme = useTheme();
    // const duration = parseInt(audioEle.current?.duration * 60); // seconds
    const [position, setPosition] = useState(0);
    const [paused, setPaused] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [volume, setVolume] = useState(1);
    const downloadOpen = Boolean(anchorEl);

    const handleDownloadClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDownloadClose = () => {
        setAnchorEl(null);
    };

    function formatDuration(value) {
        const minute = Math.floor(value / 60);
        const secondLeft = parseInt(value - minute * 60);
        return `${minute}:${secondLeft < 9 ? `0${secondLeft}` : secondLeft}`;
    }
    const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';
    const lightIconColor =
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';

    const playClickHandler = e => {
        handleAudioChange(paused)
        setPaused(!paused)
    }

    const handleSeekbarChange = (_, value) => {
        audioEle.current.currentTime = value
        setPosition(value)
    }

    const handleVolumeChange = (_, value) => {
        audioEle.current.volume = value / 100
    }

    useEffect(() => {
        if (!audioEle.current) return
        // setPosition(parseInt(audioEle.current.duration * 60))
        // audioEle.current.onTimeUpdate(() => {
        //     console.log(parseInt(audioEle.current.duration * 60))
        // })
        audioEle.current.addEventListener("timeupdate", () => {
            setPosition(parseInt(audioEle.current?.currentTime || 0))
        }, false)
        audioEle.current.addEventListener('ended', () => {
            setPaused(true)
        }, false)
        audioEle.current.addEventListener('volumechange', () => {
            setVolume(audioEle.current.volume)
        })
    }, [audioEle])

    return <>
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <Widget>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 0,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    maxWidth: 200,
                                    width: '100%',
                                    mt: 0,
                                }}
                            >
                                {/* <IconButton aria-label="previous song">
                                <FastRewindRounded fontSize="large" htmlColor={mainIconColor} />
                            </IconButton> */}
                                <IconButton
                                    aria-label={paused ? 'play' : 'pause'}
                                    onClick={playClickHandler}
                                    size="large"
                                    sx={{ p: 0 }}
                                >
                                    {paused ? (
                                        <PlayArrowRounded
                                            sx={{ fontSize: '3rem' }}
                                            htmlColor={mainIconColor}
                                        />
                                    ) : (
                                        <PauseRounded sx={{ fontSize: '3rem' }} htmlColor={mainIconColor} />
                                    )}
                                </IconButton>
                                <IconButton aria-label="previous song" onClick={handleFlowChange} size="large" sx={{ p: 0 }}>
                                    {flow ? <Flow fontSize="large" htmlColor={mainIconColor} /> : <FlowOff fontSize="large" htmlColor={mainIconColor} />}
                                </IconButton>
                                {/* <IconButton aria-label="next song">
                            <FastForwardRounded fontSize="large" htmlColor={mainIconColor} />
                        </IconButton> */}
                                {session.mediaType === 'video' &&
                                    <IconButton aria-label="next song" onClick={handleVideoClick} size="large" sx={{ p: 0 }}>
                                        {videoOpen ? <Tv fontSize="large" htmlColor={mainIconColor} /> : <TvOff fontSize="large" htmlColor={mainIconColor} />}
                                    </IconButton>

                                }
                                <IconButton
                                    aria-label="previous song"
                                    aria-controls={downloadOpen ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={downloadOpen ? 'true' : undefined}
                                    onClick={handleDownloadClick}
                                    size="large"
                                    sx={{ p: 0 }}
                                >
                                    <FileDownload fontSize="large" htmlColor={mainIconColor} />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={downloadOpen}
                                    onClose={handleDownloadClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => { handleDownloadCurrent(); handleDownloadClose(); }}>Download current project</MenuItem>
                                    <MenuItem onClick={() => { handleDownloadFinal(); handleDownloadClose(); }}>Download Final project</MenuItem>
                                    <MenuItem onClick={() => { handleDownloadSession(); handleDownloadClose(); }}>Download Session</MenuItem>
                                </Menu>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Slider
                            aria-label="time-indicator"
                            size="small"
                            value={position}
                            min={0}
                            step={1}
                            max={duration}
                            onChange={handleSeekbarChange}
                            sx={{
                                color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                                mt: 1,
                                height: 4,
                                '& .MuiSlider-thumb': {
                                    width: 8,
                                    height: 8,
                                    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                    '&:before': {
                                        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                                    },
                                    '&:hover, &.Mui-focusVisible': {
                                        boxShadow: `0px 0px 0px 8px ${theme.palette.mode === 'dark'
                                            ? 'rgb(255 255 255 / 16%)'
                                            : 'rgb(0 0 0 / 16%)'
                                            }`,
                                    },
                                    '&.Mui-active': {
                                        width: 20,
                                        height: 20,
                                    },
                                },
                                '& .MuiSlider-rail': {
                                    opacity: 0.28,
                                },
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mt: -2,
                            }}
                        >
                            <TinyText>{formatDuration(position)}</TinyText>
                            <TinyText>-{formatDuration(duration - position)}</TinyText>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1, mt: 1 }} alignItems="center">
                            <VolumeDownRounded htmlColor={lightIconColor} />
                            <Slider
                                aria-label="Volume"
                                value={volume * 100}
                                onChange={handleVolumeChange}
                                sx={{
                                    color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                                    '& .MuiSlider-track': {
                                        border: 'none',
                                    },
                                    '& .MuiSlider-thumb': {
                                        width: 24,
                                        height: 24,
                                        backgroundColor: '#fff',
                                        '&:before': {
                                            boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                        },
                                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                            boxShadow: 'none',
                                        },
                                    },
                                }}
                            />
                            <VolumeUpRounded htmlColor={lightIconColor} />
                        </Stack>
                    </Grid>
                </Grid>



            </Widget>
        </Box>
    </>;
}