import { useState, useEffect, useRef } from 'react';
import VideoContainer from 'src/components/videoContainer';

export default function Video({ open, onClose, videoMaxHeight, fullscreen, onFullscreenClick, recorderState, streamRef, streamStarted }) {
    const videoEle = useRef(null)
    const [minimized, setMinimized] = useState(false)

    useEffect(() => {
        if (open && videoEle.current && streamStarted) {
            if ("srcObject" in videoEle.current) {
                videoEle.current.srcObject = streamRef.current
            } else {
                console.log('no srcObject')
                videoEle.current.src = window.URL.createObjectURL(streamRef.current)
            }
            videoEle.current.play()
        } else if (videoEle.current) {
            videoEle.current.pause()
        }
    }, [open, minimized, streamStarted]);

    return (
        <VideoContainer
            open={open} onClose={onClose} videoMaxHeight={videoMaxHeight} fullscreen={fullscreen} onFullscreenClick={onFullscreenClick} disableClose={recorderState === 'recording' || recorderState === 'paused'} loading={!streamStarted} minimized={minimized} setMinimized={setMinimized}
        >
            <video ref={videoEle} width={fullscreen ? "100%" : "320"} height={fullscreen ? "100%" : "240"} muted />
        </VideoContainer>
    );
}