import React from 'react';
import { useParams } from 'react-router-dom'

// material
import { Box, Container } from '@mui/material';
import TagTabs from './sessionsList/TagTabs';

// components
import Page from 'src/components/Page';
import SessionList from './sessionsList/SessionList';
import Footer from 'src/components/Footer';

export default function LandingPage(props) {
    const { tag } = useParams()
    return (
        <Page title="CodeLens | Home">
            <Box sx={{ minHeight: '100vh', pb: 2 }}>
                <TagTabs />

                <SessionList tag={tag} />
            </Box>
            <Footer />
        </Page>
    );
}
