
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination
} from '@mui/material';
import Label from 'src/components/Label';
import { UserListHead, UserListToolbar, UserMoreMenu } from './user';

const DataTableBody = ({ values, columns, filteredUsers, page, rowsPerPage, selected, setSelected, editRoute, viewRoute, onDelete }) => {
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - values.length) : 0;
    return (
        <TableBody>
            {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, id) => {
                    //const isItemSelected = selected.indexOf(name) !== -1;
                    const isItemSelected = false

                    return (
                        <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={isItemSelected}
                                // onChange={(event) => handleClick(event, name)}
                                />
                            </TableCell>
                            {columns.map((column, id) => (
                                <TableCell align={column.alignRight ? "right" : "left"} key={id} {...column.props}>
                                    {column.render
                                        ? (
                                            column.render(row, id)
                                        )
                                        : (
                                            row[column.id]
                                        )
                                    }
                                </TableCell>
                            ))}
                            <TableCell align="right">
                                <UserMoreMenu
                                    item={row}
                                    id={id}
                                    editRoute={editRoute}
                                    viewRoute={viewRoute}
                                    onDelete={onDelete}
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
            {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                </TableRow>
            )}
        </TableBody>
    );
}

export default DataTableBody;