import React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

/*
    const options = {
        title: '',
        content: '',
        buttons: [
            {
                label: 'Yes',
                onClick: function,
                autoFocus: false
            },
            {
                label: 'No',
                onClick: false, for onClose
                autoFocus: true
            }
        ]
    };
*/

export const ConfirmationModal = ({ title, content, buttons }) => {
    confirmAlert({
        closeOnEscape: true,
        closeOnClickOutside: true,
        customUI: ({ onClose }) => (
            <Dialog
                open={true}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {buttons.map((element, index) => {
                        return (
                            <Button
                                key={index}
                                onClick={
                                    element.onClick
                                        ? () => {
                                              element.onClick();
                                              onClose();
                                          }
                                        : onClose
                                }
                                autoFocus={element.autoFocus}
                            >
                                {element.label}
                            </Button>
                        );
                    })}
                </DialogActions>
            </Dialog>
        )
    });
};
