import { Navigate } from 'react-router-dom';
import Login from 'src/pages/auth/Login';
import Register from 'src/pages/auth/Register';
import PasswordReset from 'src/pages/auth/PasswordReset';

export default [
    { path: 'home', element: <Navigate to="/" replace /> },
    { path: 'login', element: <Login /> },
    { path: 'register', element: <Register /> },
    { path: 'password-reset', element: <PasswordReset /> },
]