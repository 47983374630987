import { Avatar, Box } from '@mui/material';
import { Link } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { getIcon } from 'src/data';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
    position: 'relative',
    paddingTop: 'calc(200px * 3 / 4)'
});

const TitleStyle = styled(Link)({
    height: 30,
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical'
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(-2)
}));

const InfoStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
});

// ----------------------------------------------------------------------

const SessionItemTag = ({ tag, id = 0 }) => {
    const OFFSET = id > 2 ? (2 * 48) + (id - 2) * 40 : id * 48;
    return (
        <Link to={`/tagged/${tag}`}>
            <Box
                component="span"
                sx={{
                    bgcolor: 'background.paper',
                    width: 40,
                    height: 40,
                    zIndex: 9,
                    left: OFFSET + 20,
                    bottom: -20,
                    position: 'absolute',
                    borderRadius: '50%'
                }}
            />
            <AvatarStyle
                alt={tag}
                sx={{
                    position: 'absolute',
                    zIndex: 9,
                    left: OFFSET + 24,
                    width: 32,
                    height: 32
                }}
            >
                {getIcon(tag)}
            </AvatarStyle>

        </Link>
    );
}

export default SessionItemTag;