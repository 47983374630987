import MySessions from 'src/pages/mySessions/MySessions';
import EditSession from 'src/pages/mySessions/editSession/EditSession';
import ViewMySession from 'src/pages/mySessions/ViewMySession';
import RecordSession from 'src/pages/mySessions/RecordSession';

export default [
    { path: '', element: <MySessions /> },
    { path: 'edit/:id', element: <EditSession /> },
    { path: 'record', element: <RecordSession /> },
    { path: ':id', element: <ViewMySession /> },
]