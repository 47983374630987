import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Button, TextField } from '@mui/material';
import { Alert } from '@mui/material';
import { useAuth } from 'src/contexts/AuthContext';
import api from 'src/services/api';

function NameEditForm() {
    const { user, setUser } = useAuth();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertServerity, setAlertServerity] = useState('success');

    const NameEditSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name required'),
        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Last name required')
    });

    const formik = useFormik({
        initialValues: {
            firstName: user.firstname,
            lastName: user.lastname
        },
        validationSchema: NameEditSchema,

        onSubmit: (initialValues, actions) => {
            api.users
                .updateName({
                    firstname: initialValues.firstName,
                    lastname: initialValues.lastName
                })
                .then((res) => {
                    if (res.data.success) {
                        console.log(res.data);
                        let newUser = { ...user };
                        newUser.firstname = initialValues.firstName;
                        newUser.lastname = initialValues.lastName;
                        setUser(newUser);
                        setAlertMsg(res.data.msg);
                        setAlertServerity('success');
                        setShowAlert(true);
                    } else {
                        setAlertMsg(res.data.msg);
                        setAlertServerity('error');
                        setShowAlert(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    });

    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    const getButtonState = (values) =>
        values.firstName !== user.firstname || values.lastName !== user.lastname;

    return (
        <div>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <TextField
                        sx={{ m: 1 }}
                        label="First Name"
                        variant="outlined"
                        {...getFieldProps('firstName')}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                    />

                    <TextField
                        sx={{ m: 1 }}
                        label="Last Name"
                        variant="outlined"
                        {...getFieldProps('lastName')}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                    />
                    <Button
                        sx={{ m: 1 }}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!getButtonState(values)}
                    >
                        Save
                    </Button>
                </Form>
            </FormikProvider>
            {showAlert && (
                <Alert
                    sx={{ m: 1 }}
                    variant="filled"
                    severity={alertServerity}
                    onClose={() => setShowAlert(false)}
                >
                    {alertMsg}
                </Alert>
            )}
        </div>
    );
}

export default NameEditForm;
