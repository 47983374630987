import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Drawer } from '@mui/material';
//
import LandingPageNavbar from './LandingPageNavbar';
import LandingPageSidebar from './LandingPageSidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;
const DRAWER_WIDTH = 280;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
    backgroundColor: 'rgb(244, 246, 248)',
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 5,
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 0,
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0)
    }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);

    const handleSidebar = newOpen => {
        console.log(newOpen)
        setOpen(newOpen)
    }

    useEffect(() => {
        if (open) {
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <RootStyle>
            <LandingPageNavbar onOpenSidebar={() => handleSidebar(true)} />
            {/* <Drawer
                open={open}
                onClose={() => handleSidebar(false)}
                PaperProps={{
                    sx: { width: DRAWER_WIDTH }
                }}
            >
                <LandingPageSidebar
                    isOpenSidebar={open}
                    onCloseSidebar={() => handleSidebar(false)}
                    user={{ name: 'Pranavan Jegatheeswaran' }}
                />
            </Drawer> */}
            <MainStyle>
                <Outlet />
            </MainStyle>
        </RootStyle>
    );
}
