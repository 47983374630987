import { useState, useRef, useEffect } from "react";
import Editor from "src/components/Editor";
import { defaultFiles, applyDelta } from 'src/utils/editorUtils'

import { Helmet } from 'react-helmet-async';

const WorkSpace = () => {
    // main element
    const [availableHeight, setAvailableHeight] = useState(0)
    const mainEleRef = useRef(null)

    function onResize() {
        if (!mainEleRef.current) return
        setAvailableHeight(window.innerHeight - (mainEleRef.current.offsetTop))
        const offset = 0
        // document.querySelector('#editor').style.height = `calc(100vh - ${document.querySelector('#editor').offsetTop + offset}px)`
        mainEleRef.current.style.height = `calc(100vh - ${mainEleRef.current.offsetTop + offset}px)`
    }

    // code editor
    const [files, setFiles] = useState(defaultFiles);
    const [currentFile, setCurrentFile] = useState({ key: '' });
    const [editorValue, setEditorValue] = useState('');


    const handleContentChange = (data, delta) => {
        let newValue;
        setEditorValue(prevValue => {
            newValue = applyDelta(prevValue, delta)
            return newValue
        });
        setFiles((prevFiles) => {
            const duplicate = [...prevFiles]
            const index = duplicate.findIndex((file) => file.key === currentFile.key)
            duplicate[index] = { ...duplicate[index], content: newValue }
            return duplicate;
        });
    };

    const handleCurrentFileChange = (currentFile) => {
        setCurrentFile(currentFile)
        setEditorValue(files.find((co) => co.key === currentFile.key)?.content || []);
    }

    return (
        <>
            <Helmet>
                <title>CodeLens | Work Space</title>
            </Helmet>
            <main
                ref={ref => {
                    mainEleRef.current = ref
                    onResize()
                }}
                style={{ height: '100vh' }}
            >
                <Editor
                    setCurrentFile={handleCurrentFileChange}
                    value={editorValue}
                    onChange={handleContentChange}
                    files={files}
                    setFiles={setFiles}
                    activeFile={currentFile}
                    edit
                    height={availableHeight}
                />
            </main>
        </>
    );
}

export default WorkSpace;