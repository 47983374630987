import getInstance from './instance'

export default {
    initiate: (data, onUploadProgress) => getInstance().post('/sessions', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress,
        timeout: 0
    }),
    getMy: (data) => getInstance().get('/sessions/my', data),
    toggleStatus: (data) => getInstance().post('/sessions/my/status', data),
    delete: (id) => getInstance().delete('/sessions/my/' + id),
    updateThumbnail: (id, data) => getInstance().post('/sessions/my/thumbnail/' + id, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }),
}