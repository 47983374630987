

import { useState, useEffect } from "react";

// @mui material components


import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Box, Typography, Avatar, Container, Alert } from "@mui/material";
import SessionItem from '../home/sessionsList/SessionItem'
import { useParams } from "react-router-dom";
import useFetch from "src/hooks/useFetch";
import { LinearProgress } from "@mui/material";
import AuthorSessionItem from "./AuthorSessionItem";

// Images
// import burceMars from "/static/images/default.jpg";
// import backgroundImage from "/static/mock-images/avatars/avatar_default.jpg";

function Author() {
    const {id} = useParams()
    const [authorDetails, loading] = useFetch(`/users/author/${id}`)

    if (loading) return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress sx={{ height: '5px' }} />
        </Box>
    )
    const author={firstname: authorDetails.firstname, lastname: authorDetails.lastname, email: authorDetails.email}
    return (
        <Box position="relative" mb={5}>
            <Box
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="18.75rem"
                borderRadius="xl"
                sx={{
                backgroundImage: `url(${"/static/images/default.jpg"})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "hidden",
                }}
            />
            <Card
                sx={{
                position: "relative",
                mt: -8,
                mx: 3,
                py: 2,
                px: 2,
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <Avatar src={"/static/mock-images/avatars/avatar_default.jpg"} alt="profile-image" size="xl" shadow="sm" />
                    </Grid>
                    <Grid item>
                        <Box height="100%" mt={0.5} lineHeight={1}>
                        <Typography variant="h5" fontWeight="medium">
                            {authorDetails.firstname + ' ' + authorDetails.lastname}
                        </Typography>
                        <Typography variant="button" color="text" fontWeight="regular">
                            {authorDetails.email}
                        </Typography>
                        </Box>
                    </Grid>
                </Grid>
                {
                (authorDetails.sessions.length === 0) ? 
                <Container maxWidth="md" sx={{ mt: 4, textAlign: 'center' }}>
                    <Alert severity="info">No sessions found</Alert>
                </Container>
                :
                <Container maxWidth="xl">
                    <Grid container spacing={3} sx={{ mt: 2 }}>
                        {authorDetails.sessions.map((session, key) => (
                            <AuthorSessionItem key={key} post={session} author={author} />
                        ))}
                    </Grid>
                </Container>
                }
            </Card>
        </Box>
    );
}

export default Author;
