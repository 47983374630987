import React from "react";
import Ansi from "./AnsiToReact";
import Chalk from 'chalk';
const chalk = new Chalk.Instance({level: 3});

const TerminalOutput = ({children, error} : {children?: React.ReactChild, error?: boolean}) => {
  const output: string = error ? chalk.red(children as string) : children as string
  return (
    <div className="react-terminal-line"><Ansi>{ output }</Ansi></div>
  );
} 

export default TerminalOutput;