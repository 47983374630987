import { Box, Container, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import SessionItemTag from 'src/components/sessionLIstItem/SessionItemTag';
import SessionAuthor from 'src/components/sessionLIstItem/SessionAuthor';
import Premium from 'src/components/sessionLIstItem/Premium';
import SessionTag from "./SesssionTag";
import { fDate as formatDate } from 'src/utils/formatTime';

const SessionDescription = ({ session }) => {
    return (
        <Box>
            <Container maxWidth="xl">
                <Stack direction="column" alignItems="left" justifyContent="space-between" mt={2}>
                    <Typography variant="h4" component="div" gutterBottom>
                        {session.title}
                    </Typography>

                </Stack>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={6} display="flex" alignItems="center">
                        <Typography
                            gutterBottom
                            variant="body1"
                            sx={{ color: 'text.disabled', display: 'block' }}
                        >
                            {`${session.views || 0} views`} {formatDate(session.createdAt)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <SessionTag tags={session.tags} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">
                            {session.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SessionAuthor author={session.author} />
                    </Grid>
                </Grid>

            </Container>
        </Box >
    );
}

export default SessionDescription;