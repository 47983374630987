import React, { useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Avatar,
    Stack,
    Container,
    Grid,
    TextField,
    InputAdornment
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ProfilePicture from './ProfilePicture';
import ProfileDetails from './ProfileDetails';
import PremiumMembership from './PremiumMembership';

const styles = {
    container: {
        backgroundColor: '#cfe8fc',
        padding: '2rem',
        borderRadius: '10px'
    }
};

function Profile() {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container fixed style={styles.container} sx={{ mt: 3 }}>
            <Helmet>
                <title>CodeLens | Profile</title>
            </Helmet>
            <Grid container spacing={5} sx={{ mb: 2 }}>
                <Grid item xs={4}>
                    <ProfilePicture />
                </Grid>
                <Grid item container xs={8}>
                    <ProfileDetails />
                </Grid>
            </Grid>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Premium Membership</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {expanded === 'panel1' && <PremiumMembership />}
                </AccordionDetails>
            </Accordion>
        </Container>
    );
}

export default Profile;
