import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import ConfirmCodeForm from './ConfirmCodeForm';
import api from '../../../services/api';

// ----------------------------------------------------------------------

export default function RegisterForm() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordAgain, setShowPasswordAgain] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertServerity, setAlertServerity] = useState('success');
    const [confirmState, setConfirmState] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [isInputDisable, setInputDisable] = useState(false);

    const RegisterSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name required'),
        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Last name required'),
        email: Yup.string()
            .email('Email must be a valid email address')
            .required('Email is required'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password is too short - should be 6 chars minimum')
            .max(25, 'Password is too long - should be 25 chars maximum.')
            .matches(/(?=.*[A-Z])/, 'Password must contain 1 uppercase')
            .matches(/(?=.*[a-z])/, 'Password must contain 1 lowercase')
            .matches(/(?=.*\d)/, 'Password must contain 1 Number')
            .matches(/(?=.*[@$!%*#?&])/, 'Password must contain 1 special case character'),
        passwordAgain: Yup.string()
            .required('Confirm password is required')
            .min(6, 'Password is too short - should be 6 chars minimum')
            .max(25, 'Password is too long - should be 25 chars maximum.')
            .matches(/(?=.*[A-Z])/, 'Password must contain 1 uppercase')
            .matches(/(?=.*[a-z])/, 'Password must contain 1 lowercase')
            .matches(/(?=.*\d)/, 'Password must contain 1 Number')
            .matches(/(?=.*[@$!%*#?&])/, 'Password must contain 1 special case character')
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordAgain: ''
        },
        validationSchema: RegisterSchema,

        onSubmit: (initialValues, actions) => {
            if (initialValues.password === initialValues.passwordAgain) {
                console.log(actions);
                actions.setSubmitting(true);
                api.auth
                    .register({
                        firstname: initialValues.firstName,
                        lastname: initialValues.lastName,
                        email: initialValues.email,
                        password: initialValues.password
                    })
                    .then((res) => {
                        if (res.data.success) {
                            setAlertMsg(res.data.msg);
                            setAlertServerity('success');
                            setShowAlert(true);
                            actions.setSubmitting(false);
                            setConfirmState(true);
                            setUserEmail(initialValues.email);
                            setInputDisable(true);
                            // setTimeout(() => {
                            //     navigate('/login', { replace: true });
                            // }, 3000);
                        } else {
                            setAlertMsg(res.data.msg);
                            setAlertServerity('error');
                            setShowAlert(true);
                            actions.setSubmitting(false);
                        }
                    })
                    .catch((err) => {
                        actions.setSubmitting(false);
                        console.log(err);
                    });
            } else {
                actions.setFieldError('passwordAgain', 'Both entered passwords must be same');
                actions.setSubmitting(false);
            }
        }
    });

    const hideAlert = () => {
        setShowAlert(false);
        setAlertMsg('');
        setAlertServerity('success');
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <div>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                        {showAlert && (
                            <Alert
                                onClose={() => hideAlert()}
                                variant="filled"
                                severity={alertServerity}
                            >
                                {alertMsg}
                            </Alert>
                        )}
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <TextField
                                fullWidth
                                disabled={isInputDisable}
                                label="First name"
                                {...getFieldProps('firstName')}
                                error={Boolean(touched.firstName && errors.firstName)}
                                helperText={touched.firstName && errors.firstName}
                            />

                            <TextField
                                fullWidth
                                disabled={isInputDisable}
                                label="Last name"
                                {...getFieldProps('lastName')}
                                error={Boolean(touched.lastName && errors.lastName)}
                                helperText={touched.lastName && errors.lastName}
                            />
                        </Stack>
                        <TextField
                            fullWidth
                            disabled={isInputDisable}
                            autoComplete="username"
                            type="email"
                            label="Email address"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />
                        <TextField
                            fullWidth
                            disabled={isInputDisable}
                            autoComplete="current-password"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            {...getFieldProps('password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)} size="large">
                                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />

                        <TextField
                            fullWidth
                            disabled={isInputDisable}
                            autoComplete="current-password"
                            type={showPasswordAgain ? 'text' : 'password'}
                            label="Confirm Password"
                            {...getFieldProps('passwordAgain')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={() => setShowPasswordAgain((prev) => !prev)}
                                            size="large">
                                            <Icon icon={showPasswordAgain ? eyeFill : eyeOffFill} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            error={Boolean(touched.passwordAgain && errors.passwordAgain)}
                            helperText={touched.passwordAgain && errors.passwordAgain}
                        />

                        {!confirmState && (
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Register
                            </LoadingButton>
                        )}
                    </Stack>
                </Form>
            </FormikProvider>
            {confirmState && <ConfirmCodeForm email={userEmail} />}
        </div>
    );
}
