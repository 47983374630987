// material
import {
    Container, Grid, Stack, TextField, Typography, Card, CardContent, CardActions, CardMedia, Button, FormGroup, FormControlLabel, Switch, CircularProgress, Box, Divider
} from '@mui/material';

import FormLabel from '@mui/material/FormLabel';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'src/components/form/Form';
// components
import Page from 'src/components/Page';
import useEditData from 'src/hooks/useEditData';
import { tags } from 'src/data'
import api from 'src/services/api';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.label + " " + option.extension,
});

const MAX_TAGS = 5

const EditSession = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        title: '',
        description: '',
        tags: [],
        published: false,
        premium: false,
        defaultTerminal: false,
        defaultPreview: false
    });
    const [isLoading, setIsLoading] = useState(true);
    const [imageLoading, setImageLoading] = useState(false);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleCheckChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.checked
        });
    };

    const handleAddTag = (e, value) => {
        setValues({ ...values, tags: value })
    }

    const handlers = {
        formData: () => ({ ...values, tags: values.tags.map(tag => tag.label) }),
        //callback function to do after form submitted
        afterSubmit: () => {
            console.log('success')
            navigate('/mySessions');
        }
    }

    const onFileChange = e => {
        setImageLoading(true)
        var formData = new FormData();
        formData.append("thumbnail", e.target.files[0]);
        api.sessions.updateThumbnail(id, formData).then(res => {
            setImageLoading(false)
        })
    }

    useEditData(
        '/sessions/my/' + id,
        data => {
            if (id) {
                data.tags = tags.filter(tag => data.tags.includes(tag.label))
                setValues({
                    defaultTerminal: data.defaultSettings?.terminal || false,
                    defaultPreview: data.defaultSettings?.preview || false,
                    ...data
                })
                setIsLoading(false)
            }
        }
    )
    if (isLoading) return <></>
    return (
        <Page title="My Sessions">
            {/* <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Edit session
                    </Typography>
                </Stack>
            </Container> */}
            <Form
                title="Edit Session"
                subheader="Edit Session detail"
                handlers={handlers}
                action={"/sessions/my/" + id}
                method="PUT"
            >
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        container
                        spacing={3}
                        xs={12}
                    >
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText="Please specify the session title"
                                label="Session title"
                                name="title"
                                onChange={handleChange}
                                required
                                value={values.title}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Session description"
                                helperText="Description"
                                name="description"
                                onChange={handleChange}
                                multiline
                                rows={4}
                                required
                                value={values.description || ''}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                        >
                            <Autocomplete
                                id="combo-box-demo"
                                multiple
                                onChange={handleAddTag}
                                getOptionLabel={(option) => option.label}
                                filterOptions={filterOptions}
                                value={values.tags}
                                options={tags}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} label="Tags" helperText={values.tags.length >= MAX_TAGS && "Maximum tag amount exceeded"} />}
                                getOptionDisabled={(option) =>
                                    !!values.tags.find(tag => tag === option) || values.tags.length >= MAX_TAGS
                                }
                            />
                        </Grid>

                        <Grid
                            item
                            xs={6}
                        >
                            <FormGroup>
                                <FormControlLabel control={<Switch name="published" checked={values.published} onChange={handleCheckChange} />} label="Public" />
                            </FormGroup>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                        >
                            <FormGroup>
                                <FormControlLabel control={<Switch name="premium" checked={values.premium} onChange={handleCheckChange} />} label="Premium" />
                            </FormGroup>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                        >
                            <Divider sx={{ mb: 2 }} />
                            <FormLabel>Default Settings</FormLabel>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                        >
                            <FormGroup>
                                <FormControlLabel control={<Switch name="defaultTerminal" checked={values.defaultTerminal} onChange={handleCheckChange} />} label="Terminal" />
                            </FormGroup>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                        >
                            <FormGroup>
                                <FormControlLabel control={<Switch name="defaultPreview" checked={values.defaultPreview} onChange={handleCheckChange} />} label="Preview" />
                            </FormGroup>
                        </Grid>

                        <Grid
                            item
                            xs={4}
                        >
                            <Card
                                sx={{ display: 'flex', flexDirection: 'column' }}
                            >

                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Thumbnail
                                    </Typography>
                                    <Typography>
                                        This image will be displayed when listing the sessions.
                                    </Typography>
                                </CardContent>
                                <CardMedia
                                    component="img"
                                    sx={{
                                        // 16:9
                                        // pt: '56.25%',
                                    }}
                                    image={imageLoading ? "/static/illustrations/loader.gif" : `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_DATA_PATH}${id}-thumbnail.png`}
                                    alt="Session thumbnail"
                                />
                                <CardActions>
                                    <Button size="small">View</Button>
                                    <Button size="small" component="label" disabled={imageLoading}>Edit<input accept="image/*" type="file" onChange={onFileChange} hidden></input></Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        </Page>
    );
}

export default EditSession;