import eyeFill from '@iconify/icons-eva/eye-fill';
import messageCircleFill from '@iconify/icons-eva/message-circle-fill';
import shareFill from '@iconify/icons-eva/share-fill';
import { Avatar, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
// material
import { alpha, styled } from '@mui/material/styles';
import { NavLink as RouterLink, Link } from 'react-router-dom';
// utils
import { fToNow } from 'src/utils/formatTime';
import SessionItemTag from 'src/components/sessionLIstItem/SessionItemTag';
import SessionAuthor from 'src/components/sessionLIstItem/SessionAuthor';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
    position: 'relative',
    paddingTop: 'calc(200px * 3 / 4)'
});

const TitleStyle = styled(Link)({
    height: 30,
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical'
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(-2)
}));

const InfoStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
});

// ----------------------------------------------------------------------

export default function AuthorSessionItem({ post, index, author }) {
    const { cover, title, views, comment, share, createdAt } = post;
    const latestPostLarge = index === 0;
    const latestPost = index === 1 || index === 2;

    return (
        <Grid item xs={12} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 6 : 3}>
            <Card sx={{ position: 'relative' }}>
                <CardMediaStyle
                    sx={{
                        ...((latestPostLarge || latestPost) && {
                            pt: 'calc(100% * 4 / 3)',
                            '&:after': {
                                top: 0,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                            }
                        }),
                        ...(latestPostLarge && {
                            pt: {
                                xs: 'calc(100% * 4 / 3)',
                                sm: 'calc(100% * 3 / 4.66)'
                            }
                        })
                    }}
                >
                    {post.tags?.map((tag, id) => (
                        <SessionItemTag tag={tag} id={id} key={id} />
                    ))}
                    <Link to={"/sessions/" + post._id}>
                        <CoverImgStyle alt={title} src={`${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_DATA_PATH}${post._id}-thumbnail.png`} />
                    </Link>
                </CardMediaStyle>

                <CardContent
                    sx={{
                        pt: 3,
                        pb: 0,
                        ...((latestPostLarge || latestPost) && {
                            bottom: 0,
                            width: '100%',
                            position: 'absolute'
                        }),
                    }}
                >
                    <TitleStyle
                        to={"/sessions/" + post._id}
                        color="inherit"
                        variant="subtitle2"
                        underline="hover"
                        component={RouterLink}
                        sx={{
                            ...(latestPostLarge && { typography: 'h5', height: 60 }),
                            ...((latestPostLarge || latestPost) && {
                                color: 'common.white'
                            })
                        }}
                    >
                        {title}
                    </TitleStyle>
                    <Typography
                        gutterBottom
                        variant="caption"
                        sx={{ color: 'text.disabled', display: 'block' }}
                    >
                        {`${views || 0} views`} {fToNow(createdAt)}
                    </Typography>
                    <SessionAuthor author={author} />
                    {/* <InfoStyle>
                        {POST_INFO.map((info, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    ml: index === 0 ? 0 : 1.5,
                                    ...((latestPostLarge || latestPost) && {
                                        color: 'grey.500'
                                    })
                                }}
                            >
                                <Box component={Icon} icon={info.icon} sx={{ width: 16, height: 16, mr: 0.5 }} />
                                <Typography variant="caption">{fShortenNumber(info.number)}</Typography>
                            </Box>
                        ))}
                    </InfoStyle> */}
                </CardContent>
            </Card>
        </Grid>
    );
}
