import { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material';
import SessionControls from './SessionControls'

const Audio = ({ play, audioURL, audioTime, audioEle, handleAudioOnPlayChange, session, flow, handleFlowChange, handleDownloadCurrent, handleDownloadFinal, handleDownloadSession, videoOpen, handleVideoClick }) => {
    const [duration, setDuration] = useState(0)

    const handleAudioChange = play => {
        // handleAudioOnPlayChange(play)
        if (play) {
            audioEle.current.play()
            // handleAudioOnPlayChange()
        } else {
            audioEle.current.pause()
        }
    }

    // const localHandleTImeChange = e => {
    //     setAudioTime(audioEle.current.currentTime * 1000)
    //     handleTimeChange(e)
    // }

    useEffect(() => {
        // if (!audioEle.current) return
        // audioEle.current.addEventListener("durationchange", function (e) {
        //     if (this.duration !== Infinity) {
        //         var duration = this.duration
        //         console.log({ duration })
        //         setDuration(duration)
        //     } else {
        //         // audioEle.current.load()
        //         // audioEle.current.currentTime = 24 * 60 * 60
        //         // audioEle.current.play()
        //         // audioEle.current.pause()
        //         // audioEle.current.currentTime = 0
        //     }
        // }, false);
        // var getDuration = function (url, next) {
        //     var _player = new window.Audio(url);
        //     _player.addEventListener("durationchange", function (e) {
        //         if (this.duration != Infinity) {
        //             var duration = this.duration
        //             _player.remove();
        //             next(duration);
        //         };
        //     }, false);
        //     _player.load();
        //     _player.currentTime = 24 * 60 * 60; //fake big time
        //     _player.volume = 0;
        //     _player.play();
        //     //waiting...
        // };

        // getDuration(audioURL, function (duration) {
        //     setDuration(duration)
        // });
    }, [])

    return (
        <Box p={1} style={{ background: '#263238c9' }} id="audio-cont">
            {audioURL && (
                // <Grid container spacing={2}>
                //     <Grid item xs={0} md={0}></Grid>
                //     <Grid item xs={12} md={12}>
                <SessionControls
                    play={play}
                    handleAudioChange={handleAudioChange}
                    time={audioTime}
                    audioEle={audioEle}
                    duration={session.duration}
                    session={session}
                    audioURL={audioURL}
                    flow={flow}
                    handleFlowChange={handleFlowChange}
                    handleDownloadCurrent={handleDownloadCurrent}
                    handleDownloadFinal={handleDownloadFinal}
                    handleDownloadSession={handleDownloadSession}
                    videoOpen={videoOpen}
                    handleVideoClick={handleVideoClick}
                />
                //     {/* <audio
                //         src={audioURL}
                //         onTimeUpdate={localHandleTImeChange}
                //         // ref={audioEle}
                //         onPlay={handleAudioOnPlayChange}
                //         controls
                //         hidden
                //     /> */}
                //     {/* </Grid> */}
                //     {/* <Grid item xs={0} md={1}></Grid> */}
                // </Grid>
            )}
        </Box>
    );
};

export default Audio;
