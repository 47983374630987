import { useState } from 'react';
import { Link } from 'react-router-dom'
import { Box, Tabs, Tab, Avatar, Stack, Chip } from '@mui/material';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { getIcon } from 'src/data'

const AvatarStyle = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    left: theme.spacing(0),
    bottom: theme.spacing(0),
    marginRight: theme.spacing(1),
    marginBottom: '0 !important',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    position: 'relative',
    display: 'inline-block',
    transform: 'scale(0.75)'
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    backgroundColor: '#c5c5c5',
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#1890ff',
    },
}))

const StyledTag = styled((props) => <Chip component={Link} {...props} />)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'capitalize',
    cursor: 'pointer',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    minHeight: theme.spacing(4),
    borderRadius: theme.spacing(4),
    backgroundColor: '#ddd',
    fontWeight: theme.typography.fontWeightRegular,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    transitionDuration: '100',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&.Mui-selected': {
        color: '#1890ff !important',
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&:hover': {
        color: '#fff !important',
        opacity: 1,
        backgroundColor: '#555',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));



export default function SessionTag({ tags = [] }) {

    return (
        <Box sx={{ width: '100%' }}>
            <Stack direction="row" alignItems="left" justifyContent="left" >
                {tags.map((tag, id) => (
                    <StyledTag key={id} label={tag} to={`/tagged/${tag}`} icon={
                        <AvatarStyle
                            alt={tag}
                        >
                            {getIcon(tag, "small")}
                        </AvatarStyle>
                    } iconPosition="start" />
                ))}
            </Stack>
        </Box>
    );
}