import React from 'react';

import { Box } from '@mui/material';

function RegistrationSuccesfulModal(props) {
    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <img
                    alt="transparent-success-icon"
                    src="/static/transparent-success-icon.png"
                    style={{ width: '50%', height: '50%', marginLeft: 'auto', marginRight: 'auto' }}
                />
                <strong>Welcome to Code Learning!</strong>
            </div>
        </div>
    );
}

export default RegistrationSuccesfulModal;
