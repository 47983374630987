import { Avatar, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom'
import DefaultAvatar from 'src/layouts/Landing/DefaultAvatar';

const AVATAR_SIZE = 32

const SessionAuthor = ({ author }) => {
    return (
        <Link to={`/authors/${author._id}`}>
            <Stack mb={0} direction="row" alignItems="center" justifyContent="space-between">
                {(author.profilePicture && <Avatar src={author.profilePicture} alt="photoURL" sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE }} />) || (
                    <DefaultAvatar user={author} sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE }} />
                )}
                <Typography sx={{ display: 'inline-block', width: '100%', pl: 1, fontSize: '.95rem' }}>{author.firstname + " " + author.lastname}</Typography>
            </Stack>
        </Link>
    );
}

export default SessionAuthor;