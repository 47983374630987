import React, { useState } from 'react';
import Avatar from 'react-avatar-edit';

import { makeStyles } from '@mui/styles';
import {
    Modal,
    Button,
    Link,
    Card,
    CardActions,
    CardContent,
    Typography,
    TextField,
    Stack
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuth } from 'src/contexts/AuthContext';
import api from '../../services/api';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const useStyles = makeStyles((theme) => ({
    root: {
        // minWidth: 275,
        // maxWidth: 450
    },
    title: {
        fontSize: 14
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

function ProfilePictureEdit() {
    const classes = useStyles();
    const { user, setUser } = useAuth();
    const [open, setOpen] = useState(false);
    const [picture, setPicture] = useState(null);
    const [src, setSrc] = useState(null);
    const [isDisabledSave, setIsDisabledSave] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const onClose = () => {
        setPicture(null);
        setIsDisabledSave(true);
    };

    const onCrop = (picture) => {
        setIsDisabledSave(false);
        setPicture(picture);
        console.log(picture);
    };

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 2 * 1024 * 1024) {
            alert('File is too big!');
            elem.target.value = '';
        }
    };

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setIsDisabledSave(true);
    };

    const onProfilePicSave = () => {
        setIsSaving(true);
        api.users
            .updateProfilePicture({ profilePicture: picture })
            .then((res) => {
                let newUser = { ...user };
                newUser.profilePicture = picture;
                setUser(newUser);

                setIsSaving(false);
                handleCloseModal();
            })
            .catch((err) => {
                console.log(err.message);
            });
    };
    const onProfilePicRemove = (onClose) => {
        api.users
            .removeProfilePicture()
            .then((res) => {
                let newUser = { ...user };
                newUser.profilePicture = null;
                setUser(newUser);
                onClose();
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const profilePicRemoveBtnOption = {
        title: 'Remove Your Profile Picture',
        content: 'Are you sure you want to remove your profile picture?',
        buttons: [
            {
                label: 'Yes',
                onClick: onProfilePicRemove,
                autoFocus: false
            },
            {
                label: 'No',
                onClick: false,
                autoFocus: true
            }
        ]
    };

    return (
        <div>
            <Stack direction="row" spacing={2}>
                <Button onClick={handleOpenModal} variant="contained" color="primary">
                    Change
                </Button>
                {user.profilePicture && (
                    <Button
                        onClick={() => ConfirmationModal(profilePicRemoveBtnOption)}
                        variant="contained"
                        color="error"
                    >
                        Remove
                    </Button>
                )}
            </Stack>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Change your profile picture
                        </Typography>

                        <Avatar
                            width={'auto'}
                            height={200}
                            onCrop={onCrop}
                            onClose={onClose}
                            onBeforeFileLoad={onBeforeFileLoad}
                            src={src}
                            exportAsSquare
                            exportSize={96}
                            exportMimeType={'image/jpeg'}
                            exportQuality={0.8}
                        />
                        <LoadingButton
                            onClick={onProfilePicSave}
                            style={{ marginTop: '1rem' }}
                            disabled={isDisabledSave}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSaving}
                        >
                            Save
                        </LoadingButton>
                    </CardContent>
                </Card>
            </Modal>
        </div>
    );
}
export default ProfilePictureEdit;
