import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useRecorder from './useRecorder';
import { stopwatch } from 'durations';
import Audio from './Audio';
import Video from './Video';
import Editor from 'src/components/Editor';
import Player from 'src/components/Player'
import api from 'src/services/api';
import html2canvas from 'html2canvas'
import { applyDelta, defaultFiles } from 'src/utils/editorUtils'
import UploadProgress from './UploadProgress';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

const SNAPSHOT_AT = 10;
const CODIO_VERSION = "1.0.0";

const watch = stopwatch();

function Recorder() {
    const navigate = useNavigate();
    const [video, setVideo] = useState(false)
    const [videoMedia, setVideoMedia] = useState('CAMERA')
    const [
        audioURL,
        recorderState,
        startRecording,
        pauseRecording,
        resumeRecording,
        stopRecording,
        prepareStream,
        stopStream,
        streamRef,
        streamStarted
    ] = useRecorder(video, videoMedia);
    const [files, setFiles] = useState(defaultFiles || []);
    // const [records, setRecords] = useState([]);
    // const [play, setPlay] = useState(false);
    const [currentFile, setCurrentFile] = useState({ key: '' });
    const [editorValue, setEditorValue] = useState([]);
    const [activeFileRecords, setActiveFileRecords] = useState([])
    // const [controls, setControls] = useState({ cursor: {}, scroll: {}, selection: {} })
    const [recorded, setRecorded] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [uploadCompleted, setUploadCompleted] = useState(false)
    const [availableHeight, setAvailableHeight] = useState(0)
    const [videoFullscreen, setVideoFullscreen] = useState(false)

    // const audioEle = useRef(null);
    const recorderStateRef = useRef(recorderState)
    const refSnapshotCount = useRef(0)
    const refTerminalSnapshotCount = useRef(0)
    const recordsRef = useRef([])
    const actionRecordsRef = useRef({ cursor: [], scroll: [], selection: [] })
    const terminalStateRef = useRef({ input: '', cwd: '', cmdRunning: false, lines: [] })
    const terminalRecordsRef = useRef([])
    const videoActionRecordsRef = useRef([])

    const mainEleRef = useRef(null)

    const generateRecordingOutput = () => {
        return {
            records: recordsRef.current,
            activeFileRecords,
            actionRecords: actionRecordsRef.current,
            terminalRecords: terminalRecordsRef.current,
            videoActionRecords: videoActionRecordsRef.current,
            terminalAvailable: !!window.electronAPI,
            version: CODIO_VERSION
        }
    }

    const recordChanges = delta => {
        // setRecords(recs => [...recs, { time: watch.duration().seconds() * 1000, file: currentFile.key, delta }])
        recordsRef.current = [...recordsRef.current, { time: watch.duration().seconds() * 1000, file: currentFile.key, delta }]
        refSnapshotCount.current += 1;
    }

    const takeRefSnapshot = (updatedFiles) => {
        // setRecords(recs => [...recs, { time: watch.duration().seconds() * 1000, refSnapshot: true, files }])
        const dupFiles = updatedFiles ? [...updatedFiles] : [...files];
        const active = dupFiles.findIndex(file => file.key === currentFile.key)
        if (active !== -1) {
            dupFiles[active] = { ...dupFiles[active], isActive: true }
        }
        recordsRef.current = [...recordsRef.current, { time: watch.duration().seconds() * 1000, refSnapshot: true, files: dupFiles }]
        refSnapshotCount.current = 0;
    }

    const initiateRecord = () => {
        takeRefSnapshot()
        takeTerminalSnapshot();
        recordVideoAction(videoFullscreen, true)
    };

    const handleStart = () => {
        watch.reset()
        initiateRecord();
        watch.start();
        startRecording();
    };

    const handlePause = () => {
        takeRefSnapshot()
        if (recorderState === 'paused') {
            watch.start();
            resumeRecording();
        } else {
            watch.stop();
            pauseRecording();
        }
    };

    const handleStop = () => {
        watch.stop();
        takeRefSnapshot()
        stopRecording();
        setRecorded(true)
    };

    const handleContentChange = (data, delta) => {
        let newValue;
        setEditorValue(prevValue => {
            newValue = applyDelta(prevValue, delta)
            return newValue
        });
        setFiles((prevFiles) => {
            const duplicate = [...prevFiles]
            const index = duplicate.findIndex((file) => file.key === currentFile.key)
            duplicate[index] = { ...duplicate[index], content: newValue }
            // duplicate.findIndex((file) => file.key === currentFile.key).content = data;
            return duplicate;
        });
        if (recorderState === 'recording') {
            if (refSnapshotCount.current >= SNAPSHOT_AT) {
                takeRefSnapshot()
            } else {
                recordChanges(delta)
            }
        }
    };

    const handleCursorChange = (cursor) => {
        if (recorderStateRef.current === 'recording') {
            actionRecordsRef.current.cursor.push({ time: watch.duration().seconds() * 1000, file: currentFile.key, record: cursor })
        }
    }

    const handleScrollChange = scroll => {
        if (recorderStateRef.current === 'recording') {
            actionRecordsRef.current.scroll.push({ time: watch.duration().seconds() * 1000, file: currentFile.key, record: scroll })
        }
    }

    const handleSelectionChange = selection => {
        if (recorderStateRef.current === 'recording') {
            actionRecordsRef.current.selection.push({ time: watch.duration().seconds() * 1000, file: currentFile.key, record: selection })
        }
    }

    const handleVideoChange = () => {
        setVideo(!video)
        setVideoMedia("CAMERA")
        if (!video) prepareStream(true, "CAMERA")
        else stopStream()
    }

    const handleScreenShareChange = () => {
        setVideo(!video)
        setVideoMedia("SCREEN")
        if (!video) prepareStream(true, "SCREEN")
        else stopStream()
    }

    const handleVideoFullscreenClick = () => {
        recordVideoAction(!videoFullscreen)
        setVideoFullscreen(fullscreen => !fullscreen)
    }

    const recordVideoAction = (fullscreen, record) => {
        if (recorderStateRef.current === 'recording' || record) {
            videoActionRecordsRef.current.push({ time: watch.duration().seconds() * 1000, fullscreen })
        }
    }

    const handleBrowserAction = (files) => {
        if (recorderState === 'recording') {
            takeRefSnapshot(files)
        }
    }

    const handleClearRecording = () => {

        setRecorded(false)
        setFiles(defaultFiles)
        setCurrentFile({ key: '' })
        setEditorValue([])
        setActiveFileRecords([])

        refSnapshotCount.current = 0;
        refTerminalSnapshotCount.current = 0;
        recordsRef.current = []
        actionRecordsRef.current = []
        terminalStateRef.current = { input: '', cwd: '', cmdRunning: false, lines: [] }
        terminalRecordsRef.current = []

    }

    const handleSave = async (callback) => {
        setIsUploading(true)
        const formData = new FormData();
        // recorded array as json file
        var blob = new Blob([JSON.stringify(generateRecordingOutput())], { type: 'text/plain' });
        formData.append('records', new File([blob], 'records', { type: 'text/plain' }));
        // recorded audio
        const media = await fetch(audioURL)
            .then((r) => r.blob())
            .then((blobFile) => new File([blobFile], 'recordedMedia', { type: video ? 'video/mp4' : 'audio/mpeg' }));
        formData.append('media', media);
        formData.append('mediaType', video ? 'video' : 'audio')
        formData.append('duration', watch.duration().seconds())

        const metaData = {
            mediaType: video ? 'video' : 'audio',
            duration: watch.duration().seconds()
        }
        const recordingBlob = new Blob([JSON.stringify({ metaData, records: generateRecordingOutput() })], { type: 'text/plain' });
        const recordingFile = new File([recordingBlob], 'records', { type: 'text/plain' })

        const zip = new JSZip();
        const folder = zip.folder("session");
        folder.file('records.json', recordingFile)
        folder.file(video ? 'media.mp4' : 'media.mpeg', media)
        folder.generateAsync({ type: 'blob' }).then(function (content) {
            FileSaver.saveAs(content, 'download.zip');
        });

        const canvas = await html2canvas(document.querySelector('#editor'))
        // var img = new Image();
        // img.src = canvas.toDataURL();
        const thumbnail = await fetch(canvas.toDataURL())
            .then((r) => r.blob())
            .then((blobFile) => new File([blobFile], 'thumbnail', { type: 'image/png' }));
        formData.append('thumbnail', thumbnail);

        // calling the service function
        api.sessions.initiate(formData, ProgressEvent => {
            setUploadProgress((ProgressEvent.loaded * 100) / ProgressEvent.total)
        }).then(({ data: { success, _id, err } }) => {
            setUploadCompleted(true)
            if (callback) callback(success)
            if (success) {
                setTimeout(() => {
                    navigate('/mySessions/edit/' + _id);
                }, 2000)
            } else {
                console.log(err);
            }
        });
    };

    useEffect(() => {
        setEditorValue(files.find((co) => co.key === currentFile.key)?.content || []);
        recorderState === 'recording' && setActiveFileRecords(activeFileRecords => (
            [...activeFileRecords, { time: watch.duration().seconds() * 1000, file: currentFile.key }]
        ))
    }, [currentFile]);

    useEffect(() => {
        recorderStateRef.current = recorderState
    }, [recorderState])

    function onResize() {
        if (!mainEleRef.current) return
        setAvailableHeight(window.innerHeight - (mainEleRef.current.offsetTop + document.querySelector('#rec-controls').clientHeight))
        const editor = document.querySelector('#editor')
        editor && (editor.style.height = `calc(100vh - ${document.querySelector('#rec-controls').clientHeight + editor.offsetTop}px)`)
        mainEleRef.current.style.height = `calc(100vh - ${mainEleRef.current.offsetTop}px)`
    }

    useEffect(() => {
        if (recorded) return
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [recorded])

    // terminal
    const terminalRef = useRef(null)

    const recordTerminal = (line) => {
        if (recorderStateRef.current === 'recording') {
            if (refTerminalSnapshotCount.current >= SNAPSHOT_AT) {
                takeTerminalSnapshot()
            } else {
                terminalRecordsRef.current.push({ time: watch.duration().seconds() * 1000, ...line })
                refTerminalSnapshotCount.current += 1;
            }
        }
    }

    const takeTerminalSnapshot = () => {
        const data = {
            ...terminalStateRef.current,
            lines: [...terminalStateRef.current.lines]
        }
        terminalRecordsRef.current.push({ time: watch.duration().seconds() * 1000, refSnapshot: true, ...data })
        refTerminalSnapshotCount.current = 0;
    }

    /*
        types
        0 - input line change
        1 - cmd lines - input / output
        2 - cmd close with returning cwd
    */

    const handleInputChange = (input) => {
        terminalStateRef.current.input = input
        recordTerminal({ type: 0, input })
    }

    const handleNewLine = newLine => {
        terminalStateRef.current.lines.push(newLine)
        if (!newLine.isOutput) {
            terminalStateRef.current.cmdRunning = true
        }
        recordTerminal({ type: 1, line: newLine })
    }

    const handleRunCmd = (cmd) => {
        //
    }

    const handleCmdClose = (cwd) => {
        terminalStateRef.current.cwd = cwd
        terminalStateRef.current.cmdRunning = false
        recordTerminal({ type: 2, cwd })
    }

    return (
        <main
            ref={ref => {
                mainEleRef.current = ref
                onResize()
            }}
            style={{ height: '100%' }}
        >
            <Video
                open={video && !recorded}
                onClose={handleVideoChange}
                videoMaxHeight={availableHeight}
                fullscreen={videoFullscreen}
                onFullscreenClick={handleVideoFullscreenClick}
                recorderState={recorderState}
                streamRef={streamRef}
                streamStarted={streamStarted}
            />
            {!recorded ?
                <Editor
                    setCurrentFile={setCurrentFile}
                    value={editorValue}
                    onChange={handleContentChange}
                    files={files}
                    handleBrowserAction={handleBrowserAction}
                    setFiles={setFiles}
                    activeFile={currentFile}
                    edit={true}
                    actions={{
                        onCursor: handleCursorChange,
                        onScroll: handleScrollChange,
                        onSelection: handleSelectionChange
                    }}
                    onTerminalAction={{
                        onInputChange: handleInputChange,
                        onNewLine: handleNewLine,
                        onRunCmd: handleRunCmd,
                        onCmdClose: handleCmdClose
                    }}
                    height={availableHeight}
                />
                :
                <Player
                    session={{ mediaType: video ? 'video' : 'audio', duration: watch.duration().seconds() }}
                    records={{ current: generateRecordingOutput() }}
                    audioURL={audioURL}
                    offsetElement="#rec-controls"
                />
            }

            <Audio
                handleStart={handleStart}
                handlePause={handlePause}
                handleStop={handleStop}
                handleVideoChange={handleVideoChange}
                handleScreenShareChange={handleScreenShareChange}
                video={video}
                recorded={recorded}
                videoMedia={videoMedia}
                // handleTimeChange={handleTimeChange}
                // audioEle={audioEle}
                // handleAudioOnPlay={handleAudioOnPlay}
                recorderState={recorderState}
                handleSave={handleSave}
                handleClearRecording={handleClearRecording}
                watch={watch}
            />
            <UploadProgress open={isUploading} uploaded={uploadProgress} completed={uploadCompleted} />
        </main>
    );
}

export default Recorder;
