import { useRoutes } from 'react-router-dom';
import LandingPageLayout from './layouts/Landing';
// layouts
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import GoogleAuthComplete from './pages/auth/GoogleAuthComplete';
import HomePage from './pages/home/Home';
import WorkSpace from './pages/workspace';
import OfflinePlayer from './pages/offlinePlayer';
import NotFound from './pages/Page404';
import Profile from './pages/profile/Profile';
import Author from './pages/author';
import About from './pages/about'
// routes
import logoOnly from './routes/logoOnly';
import mySessions from './routes/mySessions';
import search from './routes/search';
import sessions from './routes/sessions';

// ----------------------------------------------------------------------

function Router() {
    let routes = [
        {
            path: '/',
            element: <LandingPageLayout />,
            children: [
                { path: '/', element: <HomePage /> },
                { path: '/tagged/:tag', element: <HomePage /> },
                { path: 'profile', element: <Profile /> },
                { path: 'workspace', element: <WorkSpace /> },
                { path: 'offlinePlayer', element: <OfflinePlayer /> },
                { path: 'sessions', children: sessions },
                { path: 'mySessions', children: mySessions },
                { path: 'profile', element: <Profile /> },
                { path: 'search', children: search },
                { path: 'authors/:id', element: <Author /> },
                { path: 'about', element: <About /> },
            ]
        },
        {
            path: '/',
            element: <LogoOnlyLayout />,
            children: logoOnly
        },
        { path: '/', element: <NotFound /> },
        { path: 'google-auth-complete', element: <GoogleAuthComplete /> }
    ];
    return useRoutes(routes);
}
export default Router;
