import React, { useEffect, useState } from 'react';
// import styles from './Style.scss';
import DisplayComments from './components/DisplayComments';
import { ActionProvider } from './components/ActionContext';
import SignField from './components/SignField';
import Input from './components/Input';

import './style.css';

export const CommentSection = ({
    commentsArray,
    currentUser,
    setComment,
    signinUrl,
    signupUrl,
    customInput,
    addComment,
    editComment,
    deleteComment
}) => {
    const [comments, setComments] = useState(commentsArray);
    useEffect(() => {
        setComments(commentsArray);
    }, [commentsArray]);

    return (
        <ActionProvider
            currentUser={currentUser}
            setComment={setComment}
            comments={comments}
            signinUrl={signinUrl}
            signupUrl={signupUrl}
            customInput={customInput}
            addComment={addComment}
            editComment={editComment}
            deleteComment={deleteComment}
        >
            <div className="section">
                <div className="inputBox">
                    {signupUrl && !currentUser ? <SignField /> : <Input />}
                </div>
                <div className="displayComments">
                    <DisplayComments comments={comments} />
                </div>
            </div>
        </ActionProvider>
    );
};
