import { useState, useEffect, useRef } from 'react';
import CodeMirror from './CodeMirror';
import FileManager from './FileManager';
import Preview from './Preview';
import MenuBar from './MenuBar/MenuBar';
import Terminal from "src/components/Terminal";

import { Grid, Box } from '@mui/material';

const Editor = ({ children, setCurrentFile, value, onChange, files, handleBrowserAction, setFiles, activeFile, edit = false, actions, controls, onTerminalAction = {}, height, terminal: propTerminal, preview: propPreview }) => {
    const [preview, setPreview] = useState(false);
    const [showTerminal, setShowTerminal] = useState(false)
    const codemirrorRef = useRef(null);

    const handlePreviewToggle = () => {
        setPreview(!preview)
    }

    const closeCurrentFile = () => {
        setCurrentFile({ key: '' })
    }

    const handleFolderOpen = async folder => {
        const folderIndex = files.findIndex(file => file.key === folder.key)
        if (folderIndex !== -1 && window.electronAPI) {
            const innerFiles = await window.electronAPI.readFolder(cwd + "/" + folder.key).then(folderResult => folderResult.map(file => ({ key: folder.key + file.path, content: file.content.split('\n') })))
            let modified = [...files.slice(0, folderIndex), ...innerFiles, ...files.slice(folderIndex + 1, files.length)];
            setFiles(modified)
            handleBrowserAction(modified)
        }
    }

    // terminal
    const terminalRef = useRef(null)

    // menu
    const [cwd, setCwd] = useState("")

    const promptLocation = async () => {
        const filePath = await window.electronAPI.openFile()
        setCwd(filePath)
        return filePath
    }

    const saveFiles = async (newCwd) => {
        window.electronAPI.saveFiles(newCwd || cwd, files.map(file => ({ path: file.key, content: file.content.join('\n') })))
    }

    const openFolder = async () => {
        const [files, cwd] = await window.electronAPI.openFolder() || []
        if (!files) return
        const newFiles = files.map(file => ({ key: file.path, content: file.content.split('\n') }))
        setFiles(newFiles)
        handleBrowserAction && handleBrowserAction(newFiles)
        setCwd(cwd)
    }

    const handleMenuClick = menu => {
        switch (menu) {
            case 'file-save':
                if (!cwd) promptLocation().then(saveFiles)
                else saveFiles()
                break;
            case 'file-save-as':
                promptLocation().then(saveFiles)
                break;
            case 'file-open':
                openFolder()
                break;
            case 'view-preview':
                setPreview(!preview)
                break
            case 'view-terminal':
                setShowTerminal(!showTerminal)
                break
        }
    }

    const handleResize = () => {
        codemirrorRef.current.style.height = `calc(100% - ${terminalRef.current.clientHeight}px)`
    }

    const handlePress = event => {
        if ((event.ctrlKey || event.metaKey) && event.key === 's' && !!window.electronAPI) {
            event.preventDefault()
            if (!cwd) promptLocation().then(saveFiles)
            else saveFiles()
        }
    }

    useEffect(() => {
        if (!codemirrorRef.current) return
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        if (!codemirrorRef.current) return
        window.addEventListener('keydown', handlePress)
        handleResize()
        return () => {
            window.removeEventListener('keydown', handlePress)
        }
    }, [cwd, files])

    useEffect(() => {
        if (!codemirrorRef.current) return
        handleResize()
    }, [showTerminal])

    useEffect(() => {
        if (propTerminal === null || propTerminal === null) return
        setShowTerminal(propTerminal)
    }, [propTerminal])

    useEffect(() => {
        if (propPreview === null || propPreview === null) return
        setPreview(propPreview)
    }, [propPreview])

    return (
        <div id="editor">
            <Grid container spacing={0} p={0}>
                <Grid item xs={preview ? 8 : 12} md={preview ? 8 : 12} p={0} sx={{ height: '100%' }}>
                    <MenuBar onMenuClick={handleMenuClick} preview={preview} onPreviewToggle={handlePreviewToggle} />
                    <Grid container spacing={0} p={0} style={{ height: height - 30.28 }}>
                        <Grid item xs={6} sm={6} md={3} lg={2} p={0} sx={{ height: '100%' }}>
                            <FileManager
                                setCurrentFile={setCurrentFile}
                                files={files}
                                handleBrowserAction={handleBrowserAction}
                                setFiles={setFiles}
                                activeFile={activeFile}
                                onFolderOpen={handleFolderOpen}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={9} lg={10} sx={{ height: '100%' }}>
                            <CodeMirror
                                ref={codemirrorRef}
                                value={value}
                                onChange={onChange}
                                file={activeFile}
                                edit={edit}
                                actions={actions}
                                controls={controls}
                                closeCurrentFile={closeCurrentFile}
                            />
                            <Box sx={{ width: '100%' }} display={showTerminal ? "flex" : "none"} ref={terminalRef}>
                                <Box sx={{ width: !!children ? '50%' : '0%' }}>
                                    {children}
                                </Box>
                                <Box sx={{ width: !!children ? '50%' : '100%', borderLeft: '1px solid #525b61' }}>
                                    <Terminal
                                        cwd={cwd}
                                        onInputChange={onTerminalAction.onInputChange}
                                        onNewLine={onTerminalAction.onNewLine}
                                        onRunCmd={onTerminalAction.onRunCmd}
                                        onCmdClose={onTerminalAction.onCmdClose}
                                    />
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </Grid>
                {preview &&
                    <Grid item xs={4} md={4} sx={{ height: '100%' }}>
                        <Preview files={files} height={height} />
                    </Grid>
                }
            </Grid>

        </div>
    );
};

export default Editor;
