import { useState, useEffect } from 'react'
import {
    Circle as CircleIcon,
    Video as VideoIcon,
    VideoOff as VideoOffIcon,
    Pause as PauseIcon,
    Square as SquareIcon,
    Play as PlayIcon
} from 'react-feather';
import moment from 'moment'
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Button, ButtonGroup, CircularProgress, Typography } from '@mui/material';
import AudioEle from './components/AudioEle'

import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import Record from '@mui/icons-material/FiberManualRecord'
import PauseRounded from '@mui/icons-material/PauseRounded';
import StopIcon from '@mui/icons-material/Stop';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';

const MOMENT_FORMAT = 'HH:mm:ss'

const Audio = ({
    recorderState,
    handleStart,
    handlePause,
    handleStop,
    video,
    handleVideoChange,
    handleScreenShareChange,
    recorded,
    videoMedia,
    // handleTimeChange,
    // audioEle,
    // handleAudioOnPlay,
    handleSave,
    handleClearRecording,
    watch
}) => {
    const [loading, setLoading] = useState(false)
    const [time, setTime] = useState(moment.utc(0).format(MOMENT_FORMAT))

    const callback = (success) => {
        if (success) {
            setLoading(false)
        }
    }

    const handleSaveClick = () => {
        setLoading(true)
        handleSave(callback)
    }

    useEffect(() => {
        if (!recorderState || recorderState !== 'recording') return
        const interval = setInterval(() => {
            setTime(moment.utc(watch.duration().seconds() * 1000).format(MOMENT_FORMAT))
        }, 1000)
        return () => clearInterval(interval)
    }, [recorderState])

    const theme = useTheme();
    const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';

    return (
        <Box p={1} style={{ background: '#263238c9' }} id="rec-controls">

            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <ButtonGroup aria-label="outlined primary button group">
                        {!recorderState || recorderState === 'inactive' ? (
                            <Button onClick={handleStart} title="Start Recording" color='inherit'>
                                <Record
                                    sx={{ fontSize: '3rem' }}
                                // htmlColor={mainIconColor}
                                />
                            </Button>
                        ) : recorderState === 'recording' ? (
                            <Button onClick={handlePause} title="Pause Recording" color='inherit'>
                                <PauseRounded sx={{ fontSize: '3rem' }} htmlColor={mainIconColor} />
                            </Button>
                        ) : recorderState === 'paused' ? (
                            <Button onClick={handlePause} title="Resume Recording" color='inherit'>
                                <PlayArrowRounded
                                    sx={{ fontSize: '3rem' }}
                                // htmlColor={mainIconColor}
                                />
                            </Button>
                        ) : null}

                        <Button
                            onClick={handleStop}
                            disabled={
                                recorderState !== 'recording' &&
                                recorderState !== 'paused'
                            }
                            title="Stop Recording"
                            color='inherit'
                        >
                            <StopIcon
                                sx={{ fontSize: '3rem' }}
                            // htmlColor={mainIconColor}
                            />
                        </Button>
                        <Button
                            onClick={handleVideoChange}
                            title="Use camera"
                            disabled={
                                recorderState === 'recording' ||
                                recorderState === 'paused' ||
                                (video && videoMedia === 'SCREEN') ||
                                recorded
                            }
                            color='inherit'
                        >
                            {video ? <VideocamIcon sx={{ fontSize: '3rem' }} /> : <VideocamOffIcon sx={{ fontSize: '3rem' }} />}
                        </Button>
                        <Button
                            onClick={handleScreenShareChange}
                            title="Share Screen"
                            disabled={
                                recorderState === 'recording' ||
                                recorderState === 'paused' ||
                                (video && videoMedia === 'CAMERA') ||
                                recorded
                            }
                            color='inherit'
                        >
                            {video ? <ScreenShareIcon sx={{ fontSize: '3rem' }} /> : <StopScreenShareIcon sx={{ fontSize: '3rem' }} />}
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h5" component="h4" sx={{ color: 'white' }}>
                            Time {time}
                        </Typography>
                    </Box>
                </Grid>
                {recorded && (
                    <>
                        {/* <Grid item xs={9} md={6}>
                            <AudioEle
                                audioURL={audioURL}
                                handleTimeChange={handleTimeChange}
                                audioEle={audioEle}
                                handleAudioOnPlay={handleAudioOnPlay}
                                controls
                            />
                        </Grid> */}
                        <Grid item xs={12} md={7} >
                            <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                <Grid container spacing={2} sx={{ height: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Grid item xs={1} md={2} sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                        <Button
                                            variant="outlined"
                                            onClick={handleClearRecording}
                                            title="Cancel recording"
                                            disabled={loading}
                                            sx={{ minWidth: 80, minHeight: 36 }}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={1} md={2} sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                        <Button
                                            variant="contained"
                                            onClick={handleSaveClick}
                                            title="Save recording"
                                            disabled={loading}
                                            sx={{ minWidth: 80, minHeight: 36 }}
                                        >
                                            {loading ? <CircularProgress size={20} /> : "Save"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                    </>
                )}
            </Grid>
        </Box>
    );
};

export default Audio;
