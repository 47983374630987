import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { makeStyles } from '@mui/styles';
import {
  Modal,
  Button,
  Link,
  Card,
  CardActions,
  CardContent,
  Typography,
  TextField
} from '@mui/material';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Backdrop from '@mui/material/Backdrop';

import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    maxWidth: 450
  },
  title: {
    fontSize: 14
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default function ForgotPasswordModal() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertServerity, setAlertServerity] = useState('success');
  const [isDisabledSend, setDisableSend] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Email must be a valid email address').required('Email is required')
    }),
    onSubmit: (initialValues, actions) => {
      actions.setSubmitting(true);
      api.auth
        .forgotPassword({ email: initialValues.email })
        .then((res) => {
          if (res.data.success) {
            actions.setSubmitting(false);
            setDisableSend(true);
            setAlertMsg(res.data.msg);
            setAlertServerity('success');
            setShowAlert(true);
          } else {
            setAlertMsg(res.data.msg);
            setAlertServerity('error');
            setShowAlert(true);
            actions.setSubmitting(false);
          }
        })
        .catch((err) => {
          actions.setSubmitting(false);
          console.log(err);
        });
    }
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hideAlert = () => {
    setShowAlert(false);
    setAlertMsg('');
    setAlertServerity('success');
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Link component="button" onClick={handleOpen} variant="subtitle2">
        Forgot password?
      </Link>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Please enter your email address. We'll send your a link to reset your password.
                </Typography>
                {showAlert && (
                  <Alert
                    sx={{ mb: 2 }}
                    onClose={() => hideAlert()}
                    variant="filled"
                    severity={alertServerity}
                  >
                    {alertMsg}
                  </Alert>
                )}
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Eneter your email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <LoadingButton
                  disabled={isDisabledSend}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Send
                </LoadingButton>
              </CardContent>
            </Card>
          </Form>
        </FormikProvider>
      </Modal>
    </div>
  );
}
