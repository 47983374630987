import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import menuOutline from '@iconify/icons-eva/menu-outline';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Logo from '../../components/Logo';
// components
import { MHidden } from '../../components/@material-extend';
// Auth context
import { useAuth } from 'src/contexts/AuthContext';

import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 0;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

// ----------------------------------------------------------------------

LandingPageNavbar.propTypes = {
    onOpenSidebar: PropTypes.func
};

export default function LandingPageNavbar({ onOpenSidebar }) {
    const { loginStatus } = useAuth();
    return (
        <RootStyle>
            <ToolbarStyle>
                <Box sx={{ pr: 3, py: 0, display: 'inline-flex' }}>
                    {/* <div className="px-2" style={{ paddingTop: 24, color: 'black', cursor: 'pointer' }} onClick={onOpenSidebar}>
                        {getIcon(menuOutline)}
                    </div> */}
                    <Box component={RouterLink} to="/">
                        <Logo />
                    </Box>
                </Box>
                {/* <MHidden width="lgUp">
                    <IconButton
                        onClick={onOpenSidebar}
                        sx={{ mr: 1, color: 'text.primary' }}
                        size="large">
                        <Icon icon={menu2Fill} />
                    </IconButton>
                </MHidden> */}
                <Searchbar />
                <Box sx={{ flexGrow: 1 }} />

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    {loginStatus
                        ? <>
                            <NotificationsPopover />
                            <AccountPopover />
                        </>
                        : <>
                            <Button to="/login" component={RouterLink} variant="contained">
                                SIGN IN
                            </Button>
                            {/* <Button to="/register" component={RouterLink} variant="contained">
                                SIGN UP
                            </Button> */}
                        </>}
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}
