import { useState, useRef, useEffect, forwardRef } from "react";
import TerminalUi from "./TerminalUi";

const Terminal = forwardRef(({ onInputChange, onNewLine, onRunCmd, onCmdClose, cwd: inputCwd }, ref) => {
    // terminal
    const [terminalLines, setTerminalLines] = useState([])
    const [cmdRunning, setCmdRunning] = useState(true)
    const [cwd, setCwd] = useState('')

    const addTerminalLine = (newLine) => {
        setTerminalLines(terminal => [...terminal, newLine])
        onNewLine && onNewLine(newLine)
    }

    const handleTerminalInput = terminalInput => {
        if (cmdRunning) {
            addTerminalLine({ isOutput: true, text: terminalInput })
            window.electronAPI.sendStdin(terminalInput)
        } else {
            setCmdRunning(true)
            addTerminalLine({ isOutput: false, text: terminalInput, wd: cwd })
            onRunCmd && onRunCmd(terminalInput)
            window.electronAPI.runCmd(terminalInput).then(data => {
                setCwd(data.cwd)
                setCmdRunning(false)
                onCmdClose && onCmdClose(data.cwd)
            }).catch(e => {
                const errorMsg = e.message.replace("Error invoking remote method 'run-cmd': Error: ", "")
                addTerminalLine({ isOutput: true, text: errorMsg, error: true })
                setCmdRunning(false)
                onCmdClose && onCmdClose(cwd)
            })
        }
    }

    const handleTerminate = () => {
        window.electronAPI.exitAll().then(() => {
            setCmdRunning(false)
            onCmdClose && onCmdClose(cwd)
        })
    }

    useEffect(() => {
        if (!window.electronAPI) return
        const removeHandleStdOut = window.electronAPI.handleStdOut((event, value) => {
            // event.sender.send('counter-value', newValue)
            addTerminalLine({ isOutput: true, text: value })
        })
        const removeHandleStdErr = window.electronAPI.handleStdErr((event, value) => {
            addTerminalLine({ isOutput: true, text: value, error: true })
        })

        window.electronAPI.getCwd().then(cwd => {
            setCwd(cwd)
            setCmdRunning(false)
            onCmdClose && onCmdClose(cwd)
        }).catch(e => {
            setCmdRunning(false)
        })

        return () => {
            removeHandleStdOut();
            removeHandleStdErr();
        }
    }, [])

    useEffect(() => {
        if (inputCwd) {
            setCmdRunning(true)
            window.electronAPI.runCmd('cd ' + inputCwd).then(data => {
                setCwd(data.cwd)
                setCmdRunning(false)
                onCmdClose && onCmdClose(data.cwd)
            }).catch(e => {
                const errorMsg = e.message.replace("Error invoking remote method 'run-cmd': Error: ", "")
                setCmdRunning(false)
                onCmdClose && onCmdClose(cwd)
            })
        }
    }, [inputCwd])

    return (

        <TerminalUi
            onInput={handleTerminalInput}
            onInputChange={onInputChange}
            onTerminate={handleTerminate}
            prompt={cwd}
            cmdRunning={cmdRunning}
            disabled={!window.electronAPI}
            lines={terminalLines}
            ref={ref}
        />
    );
})

export default Terminal;