import { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { stopwatch } from 'durations';
import Audio from './Audio';
import Video from './Video';
import Loader from './Loader'
import Editor from 'src/components/Editor';
import TerminalUi from "src/components/Terminal/TerminalUi";
import { applyDelta, defaultFiles } from 'src/utils/editorUtils'
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import BufferLoader from './BufferLoader';

const watch = stopwatch();

function Player({ session, records = { records: [], activeFileRecords: [], terminalRecords: [], actionRecords: { cursor: [], scroll: [], selection: [] } }, audioURL, loading: externalLoading = false, offsetElement, thumbnail }) {
    const [files, setFiles] = useState(defaultFiles);
    // const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState({ jsonData: true, audio: true })
    const [buffering, setBuffering] = useState(false)
    const [error, setError] = useState(null)
    const [play, setPlay] = useState(false);
    const [currentFile, setCurrentFile] = useState({ key: '' });
    const [flow, setFlow] = useState(true)
    const [editorValue, setEditorValue] = useState('');
    // const [activeRecord, setActiveRecord] = useState({ record: 0, activeFile: 0, files: 0 })
    const [controls, setControls] = useState({ cursor: {}, scroll: {}, selection: {} })
    // terminal
    const [terminalInput, setTerminalInput] = useState("")
    const [terminalLines, setTerminalLines] = useState([])
    const [cmdRunning, setCmdRunning] = useState(true)
    const [cwd, setCwd] = useState('')
    const [availableHeight, setAvailableHeight] = useState(0)
    const [videoOpen, setVideoOpen] = useState(true);
    const [videoFullscreen, setVideoFullscreen] = useState(false)

    const audioEle = useRef(session.mediaType === 'audio' ? new window.Audio() : document.createElement('video'));
    // const records = useRef({ records: [], fileRecords: [], activeFileRecords: [], actionRecords: { cursor: [], scroll: [], selection: [] } })
    const activeRecord = useRef({ record: 0, activeFile: 0, fileRecord: 0, terminal: 0, videoAction: 0 })
    const currentFileRef = useRef({ key: '' })
    const flowRef = useRef(true)
    const mainEleRef = useRef(null)
    const bufferMonitorRef = useRef(null)

    const handleContentChange = (data, delta) => {
        let newValue;
        setEditorValue(prevValue => {
            newValue = applyDelta(prevValue, delta)
            return newValue
        });
        setFiles((prevFiles) => {
            const duplicate = [...prevFiles]
            const index = duplicate.findIndex((file) => file.key === currentFile.key)
            duplicate[index] = { ...duplicate[index], content: newValue }
            // duplicate.findIndex((file) => file.key === currentFile.key).content = data;
            return duplicate;
        });
    };

    const resetPlayback = () => {
        activeRecord.current = { record: 0, activeFile: 0, fileRecord: 0, terminal: 0, videoAction: 0 }
        const firstRecord = records.current.records[0]
        if (flowRef.current) {
            const activeFile = firstRecord.files.find(file => file.isActive)
            if (activeFile) {
                currentFileRef.current = {
                    key: activeFile.key
                }
            }
            setCurrentFile(currentFileRef.current)
        }
        const editorValue = firstRecord.files.find(file => file.key === currentFileRef.current.key)?.content || []
        setEditorValue(editorValue)
        setFiles(firstRecord.files)


        if (records.current.terminalRecords && records.current.terminalRecords.length > 0) {
            const firstTerminalRecord = records.current.terminalRecords[0]
            setTerminalInput(firstTerminalRecord.input)
            setTerminalLines(firstTerminalRecord.lines)
            setCmdRunning(firstTerminalRecord.cmdRunning)
            setCwd(firstTerminalRecord.cwd)
        }

        if (records.current.videoActionRecords && records.current.videoActionRecords.length > 0) {
            setVideoFullscreen(records.current.videoActionRecords[0].fullscreen)
        }

    }

    const handleTimeChange = (e) => {
        const play = !audioEle.current.paused
        if (play) {
            // file records
            while (audioEle.current.currentTime * 1000 >= records.current.records[activeRecord.current.record].time) {
                const selected = records.current.records[activeRecord.current.record]
                if (selected && !selected.refSnapshot) {
                    setFiles(([...files]) => {
                        const changedFileIndex = files.findIndex((file) => file.key === selected.file)
                        const changedFile = files[changedFileIndex]
                        let modifiedContent;
                        if (changedFile) {
                            modifiedContent = applyDelta(changedFile.content, selected.delta)
                            files[changedFileIndex] = { ...changedFile, content: modifiedContent }
                            // changedFile.content = selected.content;
                        } else {
                            modifiedContent = applyDelta([], selected.delta)
                            files.push({ key: selected.file, content: modifiedContent })
                        }
                        if (currentFileRef.current.key === selected.file) {
                            setEditorValue(modifiedContent);
                        }
                        return files;
                    });
                } else if (selected && selected.refSnapshot) {
                    if (flowRef.current) {
                        const activeFile = selected.files.find(file => file.isActive)
                        if (activeFile) {
                            currentFileRef.current = {
                                key: activeFile.key
                            }
                        }
                        setCurrentFile(currentFileRef.current)
                    }
                    const editorValue = selected.files.find(file => file.key === currentFileRef.current.key)?.content || []
                    setEditorValue(editorValue)
                    setFiles(selected.files)
                }
                activeRecord.current.record += 1
            }

            // active file records
            const dupActiveFileRecords = [...records.current.activeFileRecords]
            dupActiveFileRecords.reverse()
            const selectedActiveFileIndex = dupActiveFileRecords.findIndex(
                (record) => audioEle.current.currentTime * 1000 >= record.time
            );

            if (selectedActiveFileIndex !== activeRecord.current.activeFile) {
                // setActiveRecord({ ...activeRecord, activeFile: selectedActiveFileIndex })
                activeRecord.current.activeFile = selectedActiveFileIndex
                const selected = dupActiveFileRecords[selectedActiveFileIndex];

                if (selected) {
                    setFiles((files) => {
                        const prevFiles = [...files].map(({ ...file }) => {
                            if (file.key === selected.file) {
                                if (flowRef.current) {
                                    setCurrentFile({ ...file })
                                    currentFileRef.current = { ...file }
                                }
                                file.isActive = true
                            } else {
                                file.isActive = false
                            }
                            return file
                        })
                        return prevFiles;
                    });
                }
            }

            // //file browser actions
            // const dupFileRecords = [...records.current.fileRecords]
            // dupFileRecords.reverse()
            // const selectedFileRecordIndex = dupFileRecords.findIndex(
            //     (record) => audioEle.current.currentTime * 1000 >= record.time
            // );
            // // console.log(selectedFileRecordIndex, activeRecord.current.fileRecord)
            // if (selectedFileRecordIndex !== activeRecord.current.fileRecord) {
            //     // setActiveRecord({ ...activeRecord, file: selectedFileRecordIndex })
            //     activeRecord.current.fileRecord = selectedFileRecordIndex
            //     const selected = dupFileRecords[selectedFileRecordIndex];
            //     // console.log(selected)
            //     if (selected) {
            //         setFiles(selected.files)
            //     }
            // }

            //cursor selected
            let selectedCursor = records.current.actionRecords.cursor.filter(
                (record) => audioEle.current.currentTime * 1000 >= record.time
            );
            selectedCursor = selectedCursor[selectedCursor.length - 1];
            if (selectedCursor && selectedCursor.file === currentFileRef.current.key) {
                setControls(({ ...controls }) => {
                    controls.cursor = selectedCursor.record
                    return controls
                })
            }

            //scroll selected
            let selectedScroll = records.current.actionRecords.scroll.filter(
                (record) => audioEle.current.currentTime * 1000 >= record.time
            );
            selectedScroll = selectedScroll[selectedScroll.length - 1];
            if (selectedScroll && selectedScroll.file === currentFileRef.current.key) {
                setControls(({ ...controls }) => {
                    controls.scroll = selectedScroll.record
                    return controls
                })
            }

            //selection selected
            let selectedSelection = records.current.actionRecords.selection.filter(
                (record) => audioEle.current.currentTime * 1000 >= record.time
            );
            selectedSelection = selectedSelection[selectedSelection.length - 1];
            if (selectedSelection && selectedSelection.file === currentFileRef.current.key) {
                setControls(({ ...controls }) => {
                    controls.selection = selectedSelection.record
                    return controls
                })
            }

            // terminal
            while (activeRecord.current.terminal < records.current.terminalRecords.length && audioEle.current.currentTime * 1000 >= records.current.terminalRecords[activeRecord.current.terminal].time) {
                const selected = records.current.terminalRecords[activeRecord.current.terminal]
                if (selected && !selected.refSnapshot) {
                    switch (selected.type) {
                        case 0: // input line change
                            setTerminalInput(selected.input)
                            break;
                        case 1: // cmd lines - input / output
                            setTerminalLines(lines => [...lines, selected.line])
                            if (!selected.isOutput) {
                                setTerminalInput("")
                                setCmdRunning(true)
                            }
                            break;
                        case 2: // cmd close with returning cwd
                            setCwd(selected.cwd)
                            setCmdRunning(false)
                            break;
                    }
                } else if (selected && selected.refSnapshot) {
                    setTerminalInput(selected.input)
                    setTerminalLines(selected.lines)
                    setCmdRunning(selected.cmdRunning)
                    setCwd(selected.cwd)
                }
                activeRecord.current.terminal += 1
            }

            // video action
            while (activeRecord.current.videoAction < records.current.videoActionRecords.length && audioEle.current.currentTime * 1000 >= records.current.videoActionRecords[activeRecord.current.videoAction].time) {
                const selected = records.current.videoActionRecords[activeRecord.current.videoAction]
                if (selected) {
                    setVideoFullscreen(selected.fullscreen)
                }
                activeRecord.current.videoAction += 1
            }

        }
    };

    const handleAudioOnPlayChange = (play) => {
        setPlay(play);
    };

    const handleAudioLoaded = () => {
        setLoading(({ ...loading }) => {
            loading.audio = false
            return loading
        })
    }

    const handleCurrentFileChange = (currentFile) => {
        setCurrentFile(currentFile)
        setFlow(false)
        flowRef.current = false
    }

    const handleFlowChange = () => {
        setFlow(!flow)
        flowRef.current = !flow
        if (!flow) {
            setCurrentFile(files.find(file => file.isActive))
        }
    }

    const downloadProject = (files) => {
        const zip = new JSZip();
        const folder = zip.folder("project");
        files.forEach(file => {
            folder.file(file.key, file.content.join('\n'))
        })
        folder.generateAsync({ type: 'blob' }).then(function (content) {
            FileSaver.saveAs(content, 'download.zip');
        });
    }

    const handleDownloadCurrent = () => {
        downloadProject(files)
    }

    const handleDownloadFinal = () => {
        const _records = records.current.records
        downloadProject(_records[_records.length - 1].files)
    }

    const handleDownloadSession = async () => {
        const recording = JSON.stringify({ metaData: session, records: records.current })
        const media = await fetch(audioURL).then(res => res.blob())
            .then((blobFile) => new File([blobFile], 'recordedMedia', { type: session.mediaType === 'video' ? 'video/mp4' : 'audio/mpeg' }));
        const zip = new JSZip();
        const folder = zip.folder("session");
        folder.file('records.json', recording)
        folder.file(session.mediaType === 'video' ? 'media.mp4' : 'media.mpeg', media)
        folder.generateAsync({ type: 'blob' }).then(function (content) {
            FileSaver.saveAs(content, 'download.zip');
        });
    }

    const handleVideoClick = () => {
        setVideoOpen(!videoOpen);
    };

    const handleVideoFullscreenClick = () => {
        setVideoFullscreen(fullscreen => !fullscreen)
    }

    const monitorBuffering = () => {
        if (audioEle.current.networkState === audioEle.current.NETWORK_LOADING) {
            setBuffering(true)
            bufferMonitorRef.current = setInterval(() => {
                if (audioEle.current.networkState !== audioEle.current.NETWORK_LOADING) {
                    setBuffering(false)
                    clearInterval(bufferMonitorRef.current)
                }
            }, 100)
        }
    }

    useEffect(() => {
        setEditorValue(files.find((co) => co.key === currentFile.key)?.content || []);
        currentFileRef.current = currentFile
    }, [currentFile]);

    useEffect(() => {
        // if (audioEle.mediaType === 'audio') {
        audioEle.current.src = audioURL
        // }
        audioEle.current.ontimeupdate = handleTimeChange
        audioEle.current.onplay = () => {
            monitorBuffering()
            handleAudioOnPlayChange(true)
        }
        audioEle.current.onpause = () => {
            handleAudioOnPlayChange(false)
        }
        audioEle.current.controls = true
        audioEle.current.hidden = true
        audioEle.current.oncanplaythrough = handleAudioLoaded
        audioEle.current.onerror = () => {
            setError("Something went wrong")
        }
        audioEle.current.onended = () => {
            setPlay(false)
            audioEle.current.currentTime = 0
            audioEle.current.load()
            resetPlayback()
        }
        audioEle.current.onseeking = function () {
            monitorBuffering()
            setFiles(() => {
                let dupRecords = [...records.current.records]
                let currentFile = currentFileRef.current;
                // setCurrentFile(current => {
                //     currentFile = current
                //     return current
                // })
                dupRecords.reverse()
                const refSnapshotIndex = dupRecords.findIndex(rec => (rec.refSnapshot && audioEle.current.currentTime * 1000 >= rec.time));
                const files = [...dupRecords[refSnapshotIndex].files]
                dupRecords = dupRecords.slice(0, refSnapshotIndex)
                let activeFileAvailable = false;
                let lastActiveFile = {};
                // active file records
                const dupActiveFileRecords = [...records.current.activeFileRecords]
                dupActiveFileRecords.reverse()
                const selectedActiveFileIndex = dupActiveFileRecords.findIndex(
                    (record) => audioEle.current.currentTime * 1000 >= record.time
                );
                if (selectedActiveFileIndex !== -1) {
                    activeRecord.current.activeFile = selectedActiveFileIndex
                    lastActiveFile = dupActiveFileRecords[selectedActiveFileIndex] || {};
                }

                files.forEach((file, id) => {
                    let selectedDaltas = dupRecords.filter(rec => (rec.file === file.key && audioEle.current.currentTime * 1000 >= rec.time));
                    selectedDaltas.reverse()
                    let content = file.content;
                    selectedDaltas.forEach(selected => {
                        content = applyDelta(content, selected.delta)
                        files[id] = { ...file, content }
                    })
                    if (flowRef.current && ((file.isActive && currentFile.key !== file.key) || (file.key === lastActiveFile.file))) {
                        setCurrentFile(file)
                        setEditorValue(content);
                    }
                    if (currentFile.key === file.key) {
                        setEditorValue(content);
                    }
                    if (file.isActive) activeFileAvailable = true
                    if (file.key === lastActiveFile.file) {
                        files[id] = { ...files[id], isActive: true }
                        activeFileAvailable = true
                    }
                })

                if (flowRef.current && !activeFileAvailable) {
                    setCurrentFile({ key: '' })
                }

                activeRecord.current.record = records.current.records.findIndex(rec => (rec.refSnapshot && audioEle.current.currentTime * 1000 < rec.time)) - 1
                return files
            })
        };

        return () => {
            if (!audioEle.current) return
            audioEle.current.ontimeupdate = null;
            audioEle.current.onplay = null;
            audioEle.current.onpause = null;
            audioEle.current.oncanplaythrough = null;
            audioEle.current.onerror = null;
            audioEle.current.onended = null;
            audioEle.current.onseeking = null;

            audioEle.current.pause();
            audioEle.current.currentTime = 0;

            if (bufferMonitorRef.current) clearInterval(bufferMonitorRef.current)
        }

    }, [audioURL]);

    function onResize() {
        if (!mainEleRef.current) return
        // document.querySelector('#editor').style.height = `calc(100% - ${document.querySelector('#audio-cont').clientHeight + 30.28}px)`
        const offset = document.querySelector(offsetElement)?.clientHeight || 0
        setAvailableHeight(window.innerHeight - (mainEleRef.current.offsetTop + document.querySelector('#audio-cont').clientHeight + offset))
        mainEleRef.current.style.height = `calc(100vh - ${mainEleRef.current.offsetTop + offset}px)`
    }

    useEffect(() => {
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    useEffect(() => {
        if (externalLoading || loading.audio) return
        resetPlayback()
    }, [externalLoading, loading.audio])

    // player terminal
    const terminalRef = useRef()

    if (externalLoading || loading.audio) return <Loader error={error} thumbnail={thumbnail} />

    return (
        <main
            ref={ref => {
                mainEleRef.current = ref
                onResize()
            }}
            style={{ height: '100%' }}
        >
            {session.mediaType === 'video' &&
                <Video
                    open={videoOpen}
                    src={audioURL}
                    audioEle={audioEle}
                    play={play}
                    onClose={handleVideoClick}
                    videoMaxHeight={availableHeight}
                    fullscreen={videoFullscreen}
                    onFullscreenClick={handleVideoFullscreenClick}
                    loading={buffering}
                />
            }
            <BufferLoader buffering={buffering} />
            <Editor
                setCurrentFile={handleCurrentFileChange}
                value={editorValue}
                onChange={handleContentChange}
                files={files}
                setFiles={setFiles}
                activeFile={currentFile}
                edit={!play}
                controls={controls}
                childRef={terminalRef}
                height={availableHeight}
                terminal={session.defaultSettings?.terminal}
                preview={session.defaultSettings?.preview}
            >
                {records.current.terminalAvailable && <TerminalUi
                    lineInput={terminalInput}
                    lines={terminalLines}
                    cmdRunning={cmdRunning}
                    prompt={cwd}
                    readonly
                />}
            </Editor>
            <BufferLoader buffering={buffering} />
            <Audio
                play={play}
                audioURL={audioURL}
                audioEle={audioEle}
                handleAudioOnPlayChange={handleAudioOnPlayChange}
                session={session}
                flow={flow}
                handleFlowChange={handleFlowChange}
                handleDownloadCurrent={handleDownloadCurrent}
                handleDownloadFinal={handleDownloadFinal}
                handleDownloadSession={handleDownloadSession}
                videoOpen={videoOpen}
                handleVideoClick={handleVideoClick}
            />
        </main>
    );
}

export default Player;
