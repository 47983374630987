import { format, formatDistanceToNow } from 'date-fns';
import moment from 'moment';
import numeral from 'numeral';
// ----------------------------------------------------------------------

export function fDate(date) {
    return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true
    });
}

export function getDuration(startTime, endTime) {
    const diff = moment.duration(moment(endTime).diff(startTime));
    const h = Math.floor(diff.asHours());
    const m = Math.floor(diff.asMinutes() - h * 60);
    if (h > 0 && m === 0) {
        return moment.duration(h, 'hours').humanize();
    }
    if (m > 0 && h === 0) {
        return moment.duration(m, 'minutes').humanize();
    } else {
        return (
            moment.duration(h, 'hours').humanize() + ' ' + moment.duration(m, 'minutes').humanize()
        );
    }
}

export function formatDuration(dur) {
    const duration = moment.duration(dur * 1000)
    const h = Math.floor(duration.asHours());
    const m = Math.floor(duration.asMinutes() - h * 60);
    const s = Math.floor(duration.asSeconds() - h * 60 * 60 - m * 60);
    if (h > 0) {
        return `${h}:${numeral(m).format("00")}:${numeral(s).format("00")}`
    } else {
        return `${m}:${numeral(s).format("00")}`
    }
}