import React from 'react';
import Ansi from './AnsiToReact';
import TerminalOutput from './TerminalOutput';
import Chalk from 'chalk';
const chalk = new Chalk.Instance({ level: 3 });

const TerminalGreeting = () => {
    // const greetingText = "Welcome to " + chalk.bold.bgWhite(" " + chalk.blueBright('C') + chalk.grey('ODI') + chalk.yellow('O') + " ") + " Terminal";
    const greetingText = 'Welcome to CodeLens Terminal';

    return (
        <>
            <TerminalOutput>{greetingText}</TerminalOutput>
            <TerminalOutput></TerminalOutput>
        </>
    );
};

export default TerminalGreeting;
