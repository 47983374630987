import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';

// material
import { Stack, Button, Divider, Typography } from '@mui/material';

import React from 'react';

// ----------------------------------------------------------------------

export default function AuthSocial() {
    return (
        <>
            <Stack direction="row" spacing={2}>
                <Button
                    onClick={() =>
                        window.open(`${process.env.REACT_APP_API_HOST}/auth/google`, '_self')
                    }
                    fullWidth
                    size="large"
                    color="inherit"
                    variant="outlined"
                >
                    <Icon icon={googleFill} color="#DF3E30" height={24} />
                    <Typography style={{ marginLeft: '10px', textTransform: 'none' }} variant="h5">
                        Continue with Google
                    </Typography>
                </Button>

                {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <Icon icon={facebookFill} color="#1877F2" height={24} />
        </Button>

        <Button fullWidth size="large" color="inherit" variant="outlined" visuallyHidden>
          <Icon icon={twitterFill} color="#1C9CEA" height={24} />
        </Button> */}
            </Stack>

            <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    OR
                </Typography>
            </Divider>
        </>
    );
}
