import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import api from '../../../services/api';

// ----------------------------------------------------------------------

export default function RegisterForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordAgain, setShowPasswordAgain] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertServerity, setAlertServerity] = useState('success');

    const user = queryString.parse(location.search);

    const PassowrdRestSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password is too short - should be 6 chars minimum')
            .max(25, 'Password is too long - should be 25 chars maximum.')
            .matches(/(?=.*[A-Z])/, 'Password must contain 1 uppercase')
            .matches(/(?=.*[a-z])/, 'Password must contain 1 lowercase')
            .matches(/(?=.*\d)/, 'Password must contain 1 Number')
            .matches(/(?=.*[@$!%*#?&])/, 'Password must contain 1 special case character'),
        passwordAgain: Yup.string()
            .required('Confirm password is required')
            .min(6, 'Password is too short - should be 6 chars minimum')
            .max(25, 'Password is too long - should be 25 chars maximum.')
            .matches(/(?=.*[A-Z])/, 'Password must contain 1 uppercase')
            .matches(/(?=.*[a-z])/, 'Password must contain 1 lowercase')
            .matches(/(?=.*\d)/, 'Password must contain 1 Number')
            .matches(/(?=.*[@$!%*#?&])/, 'Password must contain 1 special case character')
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordAgain: ''
        },
        validationSchema: PassowrdRestSchema,

        onSubmit: (initialValues, actions) => {
            if (initialValues.password === initialValues.passwordAgain) {
                actions.setSubmitting(true);
                api.auth
                    .resetPassword({
                        userId: user.userId,
                        token: user.token,
                        email: user.email,
                        password: initialValues.password
                    })
                    .then((res) => {
                        if (res.data.success) {
                            setAlertMsg(res.data.msg);
                            setAlertServerity('success');
                            setShowAlert(true);
                            setTimeout(() => {
                                actions.setSubmitting(false);
                                navigate('/login', { replace: true });
                            }, 3000);
                        } else {
                            setAlertMsg(res.data.msg);
                            setAlertServerity('error');
                            setShowAlert(true);
                            actions.setSubmitting(false);
                        }
                    })
                    .catch((err) => {
                        actions.setSubmitting(false);
                        console.log(err);
                    });
            } else {
                actions.setFieldError('passwordAgain', 'Both entered passwords must be same');
                actions.setSubmitting(false);
            }
        }
    });

    const hideAlert = () => {
        setShowAlert(false);
        setAlertMsg('');
        setAlertServerity('success');
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    {showAlert && (
                        <Alert
                            onClose={() => hideAlert()}
                            variant="filled"
                            severity={alertServerity}
                        >
                            {alertMsg}
                        </Alert>
                    )}
                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="New Password"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)} size="large">
                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPasswordAgain ? 'text' : 'password'}
                        label="Confirm New Password"
                        {...getFieldProps('passwordAgain')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={() => setShowPasswordAgain((prev) => !prev)}
                                        size="large">
                                        <Icon icon={showPasswordAgain ? eyeFill : eyeOffFill} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        error={Boolean(touched.passwordAgain && errors.passwordAgain)}
                        helperText={touched.passwordAgain && errors.passwordAgain}
                    />

                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Submit
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
