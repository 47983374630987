import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import api from 'src/services/api';

const UnsubscribeMembership = ({ label = "Unsubscribe", callback }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleClick = () => {
        setIsSubmitting(true)
        api.users.premiumUnsubscription().then(res => {
            setIsSubmitting(false)
            callback()
        })
    }
    return (
        <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            loading={isSubmitting}
            onClick={handleClick}
        >
            {label}
        </LoadingButton>
    );
}

export default UnsubscribeMembership;