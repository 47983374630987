import Recorder from 'src/components/Recorder'
import Page from 'src/components/Page';

const RecordSession = () => {
    return (
        <Page title="CodeLens | Record session">
            <Recorder />
        </Page>
    );
}

export default RecordSession;