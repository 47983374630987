import getInstance from './instance';

export default {
    register: (data) => getInstance().post('auth/register', data),
    confirmRegister: (data) => getInstance().put('auth/confirm-register', data),
    login: (data) => getInstance().post('auth/login', data),
    googleAuth: () => getInstance().get('auth/google'),
    googleAuthTokenConfirm: (data) => getInstance().post('auth/google-auth-token-confirm', data),
    logout: () => getInstance().get('auth/logout'),
    forgotPassword: (data) => getInstance().post('auth/forgotPassword', data),
    resetPassword: (data) => getInstance().post('auth/resetPassword', data),
    getUser: () => getInstance().get('auth/user'),
};
