import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { NavLink as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import viewFill from '@iconify/icons-eva/book-open-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

export default function UserMoreMenu({ item, id, editRoute, viewRoute, onDelete }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return <>
    <IconButton ref={ref} onClick={() => setIsOpen(true)} size="large">
      <Icon icon={moreVerticalFill} width={20} height={20} />
    </IconButton>

    <Menu
      open={isOpen}
      anchorEl={ref.current}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        sx: { width: 200, maxWidth: '100%' }
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {viewRoute &&
        <MenuItem component={RouterLink} to={viewRoute(item, id)} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={viewFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      }
      {editRoute &&
        <MenuItem component={RouterLink} to={editRoute(item, id)} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      }
      {
        onDelete &&
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => onDelete(item, id)}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      }
    </Menu>
  </>;
}
