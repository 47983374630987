import React from 'react';
import { Box } from '@mui/material';
import NameEditForm from './NameEditForm';
import EmailAddressEdit from './EmailAddressEdit';
import PasswordChange from './PasswordChange';
import { useAuth } from 'src/contexts/AuthContext';

function ProfileDetails() {
    const { user } = useAuth();
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <div>
                <NameEditForm />
                <EmailAddressEdit />
                {!user.isgoogle && <PasswordChange />}
            </div>
        </Box>
    );
}

export default ProfileDetails;
